import { Box, Text } from 'grommet/es6';
import React, { Component } from 'react';
import styles from './Users.module.scss';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      isLoaded: false,
    };
  }

  async componentDidMount() {
    try {
      const userDetails = await axios.get(`/user/profile`);
      this.setState({
        isLoaded: true,
        userProfile: userDetails?.data,
      });
    } catch (e) {
      console.log('error occured :>> ', e);
      this.state = {
        userProfile: {},
        isLoaded: false,
      };
    }
  }

  render() {
    var { isLoaded, userProfile } = this.state;

    if (!isLoaded) {
      return <div className={styles.loadingText}>Loading.....</div>;
    } else {
      return (
        <div>
          <Box className={styles.Box}>
            <h4 className={styles.heading}>USER PROFILE</h4>
            <div className={(styles.mains, 'col-xs-9 col-md-9 col-sm-9 col-lg-9 col-xl-9 col-9 d-flex flex-column')}>
              <Text className={styles.TextNew}>First Name: {userProfile?.firstName || '--'}</Text>
              <Text className={styles.TextNew}>Last Name: {userProfile?.lastName || '--'}</Text>
              <Text className={styles.TextNew}>Gender: {userProfile?.gender || '--'} </Text>
              <Text className={styles.TextNew}>Email: {userProfile?.email || '--'}</Text>
              <Text className={styles.TextNew}>Street: {userProfile?.street || '--'}</Text>
              <Text className={styles.TextNew}>State: {userProfile?.state || '--'}</Text>
              <Text className={styles.TextNew}>City: {userProfile?.city || '--'}</Text>
              <Text className={styles.TextNew}>
                Nationality: {userProfile?.nationality || '--'}
              </Text>
              <Text className={styles.TextNew}>Zip Code: {userProfile?.zipcode || '--'}</Text>
              {userProfile?.role !== 'Admin' ? (
                <Text className={styles.TextNew}>Trade type: {userProfile?.tradeType || '--'}</Text>
              ) : null}
            </div>
            <div className={(styles.mains, 'col-3 d-flex')}>
              <button className={styles.submit1}>
                <NavLink exact to="/">
                  <Text>Back</Text>
                </NavLink>
              </button>
            </div>
          </Box>
        </div>
      );
    }
  }
}
Users.propTypes = {};

export default connect()(withRouter(Users));
