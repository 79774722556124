import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, TextInput, ResponsiveContext } from 'grommet';
import { gradeOptions } from '../../../util/formFieldsUtils';

const SizeQuality = ({ cargoSize, grade, disableGrade, handleChange, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: 'medium' }}>
          <Box direction="row" justify="evenly">
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'} alignSelf="start">
                <FormField label="Size of cargo (in bbl)">
                  <TextInput
                    color={'primary'}
                    name="cargoSize"
                    value={cargoSize || ''}
                    onChange={handleChange}
                    placeholder="Type a number"
                    type="number"
                    min="1"
                  />
                </FormField>
              </Box>
            </Box>
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'}>
                <FormField label="Grade">
                  <Select
                    plain
                    name="grade"
                    placeholder="Select a grade"
                    value={grade}
                    options={gradeOptions.map(go => (
                      <span key={go.val}>{go.lab}</span>
                    ))}
                    onChange={({ option }) => handleSelect('grade', option)}
                    disabled={disableGrade}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

SizeQuality.propTypes = {
  cargoSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  grade: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableGrade: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default SizeQuality;
