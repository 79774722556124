import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './IPFSWritingIndicator.module.scss';
import Loader from 'react-loader-spinner';

const IPFSWritingIndicator = ({ isWriting }) =>
  isWriting ? (
    <div className={styles['loader-wrapper']}>
      <Loader type="Puff" color="#fff" height={32} width={32} />
    </div>
  ) : (
    <Fragment />
  );

IPFSWritingIndicator.propTypes = {
  isWriting: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    isWriting: store.db.isWriting,
  };
};

export default connect(mapStateToProps)(IPFSWritingIndicator);
