import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, TextInput } from 'grommet/es6';
import { Close } from 'grommet-icons/es6';
import styles from './TradingHistory.module.scss';

const PriceDrop = ({ onClose, filter, price, setFilter }) => (
  <Box pad="small">
    <Box direction="row" justify="between" align="center">
      <Heading level={4} margin="small">
        Select price range
      </Heading>
      <Button icon={<Close />} onClick={onClose} />
    </Box>
    <Box direction="row" align="center">
      <TextInput
        plain
        className={styles['filter-input']}
        value={price[0] || ''}
        onChange={e => setFilter({ ...filter, price: [e.target.value, price[1]] })}
        placeholder="Minimum"
        type="number"
        step="0.01"
        min={0}
      />
      $/bbl
    </Box>
    <Box direction="row" align="center">
      <TextInput
        plain
        className={styles['filter-input']}
        value={price[1] || ''}
        onChange={e => setFilter({ ...filter, price: [price[0], e.target.value] })}
        placeholder="Maximum"
        type="number"
        step="0.01"
        min={price[0] || 0.0}
      />
      $/bbl
    </Box>
  </Box>
);

PriceDrop.propTypes = {
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  price: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default PriceDrop;
