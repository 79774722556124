import { CLEAR_USER, SET_USER } from '../actions/userActions';

const initialState = {
  rsaKey: {},
  dismissedBids: [],
  id: '',
  hardCodedIdx: -1,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        rsaKey: action.payload.rsaKey,
        dismissedBids: action.payload.dismissedBids,
        id: action.payload.id,
        hardCodedIdx: action.payload.hardCodedIdx,
      });
    case CLEAR_USER:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};
export default userReducer;
