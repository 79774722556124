import { Box, Text } from 'grommet/es6';
import React, { Component } from 'react';
import styles from './Forget.module.scss';

const Forget = ({value, setGender}) => {
  return (
    <Box className={styles.Box}>
      <h4 className={styles.h4}>Your password will be  rested  to 'Arbopen123'. You can change it once you login</h4>
      <Text className={styles.Text}>Username</Text>
      <input className={styles.inputbox1} type="text" placeholder='Enter Your Username' />
      <button className={styles.submit}>Reset</button>
    </Box>
 
  );
};
Forget.propTypes = {
};

export default Forget;
