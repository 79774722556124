import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { shippingContractOptions } from '../../../util/formFieldsUtils';

const ShippingContract = ({ shippingContract, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box align="center" direction="row" justify="evenly">
            <Box width="100%" alignSelf="start">
              <FormField label="Shipping Contract">
                <Select
                  plain
                  name="shippingContract"
                  placeholder="Select a Shipping Contract"
                  value={shippingContract}
                  options={shippingContractOptions.map(qo => (
                    <span key={qo.val}>{qo.lab}</span>
                  ))}
                  onChange={({ option }) => handleSelect('shippingContract', option)}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

ShippingContract.propTypes = {
  shippingContract: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ShippingContract;
