import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
  ResponsiveContext,
} from 'grommet';
import { LinkBottom, Search } from 'grommet-icons';
import styles from './Trading.module.scss';
import PropTypes from 'prop-types';
import { theme } from '../../theme';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { MONTHS } from '../../util/constants';

const TableArea = ({
  title,
  columns,
  data,
  type,
  setCurrentAsk,
  selected,
  setCurrentSale,
  setCurrentBid,
  dismissedBids,
  search,
  setSearch,
}) => {
  const [filter, setFilter] = useState({ saleOffers: false, forSale: false });
  const { saleOffers, forSale } = filter;

  function selectRow(datum) {
    if (type === 'products') {
      setCurrentAsk(datum);
      setCurrentSale(null);
      setCurrentBid(null);
    } else if (type === 'watchlist') {
      setCurrentAsk(null);
      setCurrentSale(null);
      setCurrentBid(datum);
    } else {
      setCurrentAsk(null);
      setCurrentSale(datum);
      setCurrentBid(null);
    }
  }

  const formatCSV = data => {
    const csvData = [];

    if (type === 'products') {
      data.forEach(d => {
        csvData.push({
          ...d,
          txTime: `${new Date(d.txTime / 1000).getDate()} 
        ${MONTHS[new Date(d.txTime / 1000).getMonth()]} - 
        ${new Date(d.txTime / 1000).toLocaleTimeString()}`,
          id: undefined,
          cargoLoadingRange: undefined,
        });
      });
    } else if (type === 'watchlist') {
      data.forEach(d => {
        csvData.push({
          ...d,
          txTime: `${new Date(d.txTime / 1000).getDate()} 
        ${MONTHS[new Date(d.txTime / 1000).getMonth()]} - 
        ${new Date(d.txTime / 1000).toLocaleTimeString()}`,
          id: undefined,
          bidSent: undefined,
          tradeProposition: undefined,
        });
      });
    } else {
      data.forEach(d => {
        csvData.push({
          ...d,
          txTime: `${new Date(d.txTime / 1000).getDate()} 
        ${MONTHS[new Date(d.txTime / 1000).getMonth()]} - 
        ${new Date(d.txTime / 1000).toLocaleTimeString()}`,
          id: undefined,
          bidIds: undefined,
        });
      });
    }

    return csvData;
  };

  const csvData = formatCSV(data.filter(d => d.id.includes(search)));
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box pad={{ vertical: size !== 'large' ? '15px' : 'small', horizontal: 'medium' }}>
          <Box align="baseline" direction="row" justify="between" className={styles.headerBox}>
            <Box direction="row" align="center">
              <Heading
                className={styles.Heading}
                level={size !== 'large' ? 4 : 3}
                margin={{ top: size !== 'large' ? '5px' : 'small' }}
              >
                {title}
              </Heading>
              <CSVLink data={csvData} filename={'extract.csv'} separator={';'}>
                <Box
                  round="full"
                  background={'focus'}
                  height={size !== 'large' ? '18px' : '25px'}
                  width={size !== 'large' ? '18px' : '25px'}
                  margin={{
                    left: size !== 'large' ? '10px' : 'small',
                    top: '0px',
                    bottom: size !== 'large' ? '20px' : 'small',
                  }}
                  className={styles.download}
                >
                  <LinkBottom color={'white'} size={size !== 'large' ? '10px' : '13px'} />
                </Box>
              </CSVLink>
            </Box>
            <Box
              align="center"
              width="fit-content"
              border={{ side: 'bottom', size: '2px', color: 'accent' }}
              direction="row"
            >
              <Search color="accent" size={size !== 'large' ? '15px' : '20px'} />
              <TextInput
                type="search"
                plain
                className={styles.search}
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={'Search by name'}
              />
            </Box>
          </Box>

          {type === 'sales' && (
            <Box>
              <Text size="12px" color={'accent-1'} margin={{ top: '10px' }}>
                Filter
              </Text>
              <Box direction="row" margin={{ top: '10px' }}>
                <Box
                  round="large"
                  background={saleOffers ? 'brand' : 'accent-3'}
                  height="20px"
                  className={styles['filter-sales']}
                  width="80px"
                  margin={{ right: 'small' }}
                >
                  <Button
                    fill
                    plain
                    label={
                      <Text size="10px" color="white">
                        Sale offers
                      </Text>
                    }
                    onClick={() => setFilter({ forSale, saleOffers: !saleOffers })}
                  />
                </Box>
                <Box
                  round="large"
                  background={forSale ? 'brand' : 'accent-3'}
                  height="20px"
                  className={styles['filter-sales']}
                  width="80px"
                >
                  <Button
                    fill
                    plain
                    label={
                      <Text size="10px" color="white">
                        For sale
                      </Text>
                    }
                    onClick={() => setFilter({ saleOffers, forSale: !forSale })}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box
            className={styles['table-wrapper']}
            height={type === 'sales' ? '370px' : type === 'products' ? '215px' : '100%'}
            margin={
              type === 'watchlist' || (type === 'sales' && size !== 'large' && { top: 'medium' })
            }
          >
            <Table>
              <TableHeader>
                <TableRow>
                  {columns.map((c, idx) => (
                    <TableCell
                      key={idx}
                      scope="col"
                      align="start"
                      className={c.name === 'quality' && styles['quality-th']}
                    >
                      <Text color={'thead'} size={size !== 'large' ? '10px' : '13px'}>
                        {c.label}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data
                  .filter(
                    d =>
                      (!saleOffers && !forSale) ||
                      (saleOffers && d.bidIds.length > 0) ||
                      (forSale && d.bidIds.length === 0),
                  )
                  .filter(d => d.name.includes(search))
                  .map(datum => (
                    <TableRow
                      key={datum.id}
                      style={{
                        background:
                          selected && datum.id === selected.id ? theme.global.colors.selected : '',
                      }}
                    >
                      {columns.map((col, idx) => (
                        <TableCell align="center" key={idx}>
                          {col.name === 'price' ? (
                            <Box>
                              <Text weight={col.weight} size={size !== 'large' ? '11px' : col.size}>
                                {datum.price[0]} $/bbl
                              </Text>
                              <Text weight={col.weight} size={size !== 'large' ? '11px' : col.size}>
                                {datum.price[1]} $/bbl
                              </Text>
                            </Box>
                          ) : (
                            <Text weight={col.weight} size={size !== 'large' ? '11px' : col.size}>
                              {`${datum[col.name]}${col.name === 'cargoSize' ? ' bbl' : ''}`}
                            </Text>
                          )}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        {type === 'products' ? (
                          <Box height="18px" width="50px" background={'danger'}>
                            <Button
                              fill
                              color={'white'}
                              className={styles['table-button']}
                              onClick={() => selectRow(datum)}
                            >
                              <Text size="9px">Ask</Text>
                            </Button>
                          </Box>
                        ) : type === 'watchlist' ? (
                          <Box height="18px" width="50px">
                            <Button
                              fill
                              color={datum.bidSent ? 'disabled' : 'success'}
                              primary
                              className={styles['table-button']}
                              onClick={() => selectRow(datum)}
                              disabled={datum.bidSent}
                            >
                              <Text color={'white'} size="9px">
                                Bid
                              </Text>
                            </Button>
                          </Box>
                        ) : datum.bidIds.some(id => !dismissedBids.includes(id)) ? (
                          <Box
                            height="18px"
                            width="50px"
                            background={'accent-2'}
                            onClick={() => selectRow(datum)}
                          >
                            <Button fill color="white" className={styles['table-button']}>
                              <Text size="9px">See offers</Text>
                            </Button>
                          </Box>
                        ) : (
                          <Box height="18px" width="50px" background={'disabled'}>
                            <Button
                              fill
                              color={'white'}
                              disabled
                              className={styles['table-button']}
                            >
                              <Text size="9px">For sale</Text>
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data.filter(d => d.id.includes(search)).length === 0 && (
              <Text size="11px" margin="auto">
                There is no data to display.
              </Text>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

TableArea.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  setCurrentAsk: PropTypes.func,
  setCurrentBid: PropTypes.func,
  setCurrentSale: PropTypes.func,
  selected: PropTypes.object,
  dismissedBids: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    dismissedBids: store.user.dismissedBids,
  };
};

export default connect(mapStateToProps)(TableArea);
