import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DataTable, Text } from 'grommet/es6';
import { MONTHS } from '../../util/constants';
import { rangeToString } from '../../util/util';
import styles from './TradesTable.module.scss';
import { CSVLink } from 'react-csv';
import { LinkBottom, LinkUp } from 'grommet-icons';

const TradesTable = ({ trades, personal }) => {
  const actionTypeRender = datum => (
    <Box
      justify="center"
      height="35px"
      round="large"
      background={datum.actionType === 'Sale' ? 'danger' : 'success'}
    >
      <Text color={'white'} size="16px" weight="bold">
        {datum.actionType}
      </Text>
    </Box>
  );

  const columns = [
    {
      property: 'actionType',
      header: 'Type of action',
      render: actionTypeRender,
    },
    {
      property: 'cargoName',
      header: 'Tracking Number',
    },
    {
      property: 'txTime',
      header: 'Transaction time',
      render: datum =>
        `${new Date(datum.txTime).getDate()} 
        ${MONTHS[new Date(datum.txTime).getMonth()]} - 
        ${new Date(datum.txTime).toLocaleTimeString()}`,
    },
    {
      property: 'cargoSize',
      header: 'Cargo size',
      render: datum => `${datum.cargoSize} bbl`,
    },
    {
      property: 'grade',
      header: 'Grade',
    },
    {
      property: 'loadingRange',
      header: 'Loading range',
      render: datum => rangeToString(datum.loadingRange),
    },
    {
      property: 'deliveryTerms',
      header: 'Delivery Terms',
    },
    {
      property: 'fromTo',
      header: 'From > To',
      render: datum => `${datum.fromTo[0]} > ${datum.fromTo[1]}`,
    },
    {
      property: 'price',
      header: 'Price Differentials',
      render: datum => `${datum.price} $/bbl`,
    },
    {
      property: 'benchmark',
      header: 'Benchmark',
    },
    {
      property: 'operationTolerance',
      header: 'Operational Tolerance (%)',
    },
    {
      property: 'options',
      header: 'Options',
    },
    {
      property: 'gtc',
      header: 'GT&Cs',
    },
    // {
    //   property: 'gtc',
    //   header: 'Expected B/L date',
    // },
  ];

  if (!personal) {
    columns.splice(0, 1);
  }

  return (
    <Box>
      <Box direction="row" justify="end" pad={{ top: 'medium', horizontal: 'xlarge' }}>
        <Button plain className={styles.extract}>
          <LinkBottom size="small" color={'focus'} />
          <Text size="20px" color={'focus'}>
            <CSVLink data={trades} filename={'extract.csv'} separator={';'}>
              Extract to CSV
            </CSVLink>
          </Text>
        </Button>
      </Box>
      <Box className={styles['table-wrapper']}>
        <DataTable
          border={{
            header: { side: 'bottom', size: '3px', color: 'white' },
            body: {
              side: 'bottom',
              color: 'brand',
              size: '1px',
            },
          }}
          columns={columns}
          data={trades}
          size="large"
          sortable
          primaryKey={'id'}
        />
        {trades.length > 6 && (
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className={styles.scrollUp}
          >
            <Box round="full" background={'white'} height={'36px'} width={'36px'}>
              <LinkUp color={'brand'} size={'24px'} />
            </Box>
          </Button>
        )}
        {trades.length === 0 && (
          <Text size="11px" margin={{ horizontal: 'auto', top: 'medium' }}>
            There is no data to display.
          </Text>
        )}
      </Box>
    </Box>
  );
};

TradesTable.propTypes = {
  trades: PropTypes.array.isRequired,
  personal: PropTypes.bool.isRequired,
};

export default TradesTable;
