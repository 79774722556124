const CLEAR_LOGGED_USER = 'CLEAR_LOGGED_USER';
const SET_LOGGED_USER = 'SET_LOGGED_USER';

const clearLoggedUser = () => {
  return {
    type: CLEAR_LOGGED_USER,
  };
};

const setLoggedUser = (token, user, session, otherDetails) => {
  return {
    type: SET_LOGGED_USER,
    payload: { token, user, session, otherDetails },
  };
};

export { CLEAR_LOGGED_USER, SET_LOGGED_USER, clearLoggedUser, setLoggedUser };
