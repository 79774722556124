import { showErrorToast } from '../../util/toast-service';

const validateSignUpForm = form => {
  const email = form?.email;
  const userPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (!userPattern.test(email)) {
    showErrorToast('Invalid email address');
    return false;
  }

  const password = form?.password;
  const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
  if (!passPattern.test(password)) {
    showErrorToast(
      '<p><b>Invalid Password. </b></p> <p>Password should have one Uppercase, one number, one special character.</p> <p>It should have minimum 8 characters, maximum upto 20 characters. </p>',
      8000,
    );
    return;
  }

  const terms = form?.terms;
  if (!terms) {
    showErrorToast('Please accept terms');
    return false;
  }

  const captchaVal = form?.captchaVal;
  if (!captchaVal) {
    showErrorToast('Please solve the captcha');
    return false;
  }

  return true;
};

const termsPopupContent = () => {
  return "<p> A Terms and Conditions agreement acts as a legal contract between you (the company) and the user.</p><p> It's where you maintain your rights to exclude users from your app in the event that they abuse your website/app, set out the rules for using your service and note other important details and disclaimers.</p><p> Having a Terms and Conditions agreement is completely optional. No laws require you to have one.</p><p> Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).</p><p> Your Terms and Conditions agreement will be uniquely yours. \nWhile some clauses are standard and commonly seen in pretty much every Terms and Conditions agreement, it's up to you to set the rules and guidelines that the user must agree to.</p>";
};

export { validateSignUpForm, termsPopupContent };
