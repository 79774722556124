import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TradesTable from '../TradesTable/TradesTable';
import {
  benchmarkDictionnary,
  qualityDictionnary,
  refineryDictionnary,
  // shippingConstraintDictionnary,
} from '../../util/formFieldsUtils';

const MyTrades = ({ docs, mySaleIds, userId }) => {
  const trades = mySaleIds
    // .filter(id => {
    //   const cargo = docs[docs[docs[id]?.tradeProposition]?.cargo];
    //   return cargo !== undefined;
    // })
    .map(id => {
      const sale = docs[id];
      const cargo = docs[docs[docs[id].tradeProposition].cargo];

      return {
        actionType: sale.buyer === userId ? 'Buy' : 'Sale',
        cargoName: cargo.name,
        operationTolerance: cargo.operationTolerance,
        options: cargo.options,
        gtc: cargo.gtc,
        deliveryTerms: cargo.deliveryTerms,
        txTime: sale.timestamp,
        grade: sale.grade,
        fromTo: [docs[sale.seller].name, docs[sale.buyer].name],
        cargoSize: sale.cargoSize,
        price: sale.price,
        benchmark: benchmarkDictionnary[cargo.benchmark],
        quality: qualityDictionnary[sale.quality],
        loadingRange: [
          (new Date(sale.loadingRangeStart).getTime() / 1000).toFixed(0),
          (new Date(sale.loadingRangeEnd).getTime() / 1000).toFixed(0),
        ],
        // shippingConstraint: shippingConstraintDictionnary[sale.shippingConstraint],
        shippingConstraint: sale.shippingConstraint,
        refinery: refineryDictionnary[sale.refinery],
      };
    })
    .sort((a, b) => b.txTime - a.txTime);

  return <TradesTable trades={trades} personal={true} />;
};

MyTrades.propTypes = {
  docs: PropTypes.objectOf(PropTypes.object),
  mySaleIds: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
};

const mapStateToProps = store => {
  return {
    docs: store.db.docs,
    mySaleIds: store.db.saleIds.filter(
      saleId =>
        store.db.docs[saleId].buyer === store.user.id ||
        store.db.docs[saleId].seller === store.user.id,
    ),
    userId: store.user.id,
  };
};

export default connect(mapStateToProps)(MyTrades);
