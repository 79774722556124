import { CLEAR_LOGGED_USER, SET_LOGGED_USER } from '../actions/logggedUserActions';

const initialState = {
  token: '',
  user: '',
  session: '',
  otherDetails: {},
};

const loggedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        session: action.payload.session,
        otherDetails: action.payload.otherDetails,
      });
    case CLEAR_LOGGED_USER:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};
export default loggedUserReducer;
