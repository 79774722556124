import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './reducers/userReducer';
import loggedUserReducer from './reducers/loggedUserReducer';
import dbReducer from './reducers/dbReducer';
import ipfsNamesReducer from './reducers/ipfsNamesReducer';

const rootReducer = combineReducers({
  user: userReducer,
  db: dbReducer,
  loggedUser: loggedUserReducer,
  ipfsNames: ipfsNamesReducer,
});

export function configureStore(initialState = {}) {
  // Middleware and store enhancers
  const enhancers = [applyMiddleware(thunk)];

  let store;
  if (process.env.NODE_ENV === 'development') {
    store = createStore(rootReducer, initialState, composeWithDevTools(...enhancers));
  } else {
    store = createStore(rootReducer, initialState, compose(...enhancers));
  }

  // For hot reloading reducers
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
