import React, { Component } from 'react';
import { Box, Button, Heading, Text, ResponsiveContext } from 'grommet';
import NameGrade from './FormFields/NameGrade';
import SizeQuality from './FormFields/SizeQuality';
import OperationTolerance from './FormFields/OperationTolerance';
import ShippingConstraint from './FormFields/ShippingConstraint';
import ShippingContract from './FormFields/ShippingContract';
import LoadingRange from './FormFields/LoadingRange';
import PriceRange from './FormFields/PriceRange';
import BenchmarkRefinery from './FormFields/BenchmarkRefinery';
import { genCargoName, genUid } from '../../util/util';
import {
  aesEncryptDate,
  aesEncryptInt,
  aesEncryptString,
  generateAesKey,
  aesEncryptFloat,
} from '../../util/aes';
import { postToDB } from '../../util/postToDB';
import { CARGO_TYPE, TRADE_PROPOSITION_TYPE } from '../../util/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rsaEncryptAesKey } from '../../util/rsa';
// import { rsaEncryptAesKey, rsaEncryptUid } from '../../util/rsa';
import { withRouter } from 'react-router';
import { benchmarkPrice, shippingConstraintOptions } from '../../util/formFieldsUtils';
import DeliveryTerms from './FormFields/DeliveryTerms';
import GTC from './FormFields/GT&C';
import Pricing from './FormFields/Pricing';
import styles from './FormFields/FormFields.module.scss';
import { sendAuditLogEntry } from '../../util/axios';
// import TargetClients from './FormFields/TargetClients';

const initialCargoState = () => {
  // generate a UID and a name
  const uid = genUid();
  const name = genCargoName(uid);
  // return complete cargo state
  return {
    id: uid,
    name,
    cargoSize: 0,
    grade: '',
    quality: '',
    options: '',
    operationTolerance: '',
    shippingContract: '',
    deliveryTerms: '',
    gtc: '',
    pricing: '',
    benchmark: '',
    loadingRangeStart: null,
    loadingRangeEnd: null,
    targetClients: [],
    minPrice: 0,
    maxPrice: 0,
    isSubmitting: false,
  };
};

const initialState = () => ({
  cargo: initialCargoState(),
  showStartCalendar: false,
  showEndCalendar: false,
  cap: [],
});

class NewCap extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
  }

  marshalCargo = rawCargo => {
    // generate a symmetric key
    const secret = generateAesKey();

    console.log('rawCargo :>> ', rawCargo);
    console.log('secret :>> ', secret);
    // encrypt rawCargo
    const cargo = {
      type: CARGO_TYPE,
      id: rawCargo.id,
      name: aesEncryptString(rawCargo.name, secret),
      grade: aesEncryptString(rawCargo.grade, secret),
      cargoSize: aesEncryptInt(rawCargo.cargoSize, secret),
      quality: aesEncryptString(rawCargo.quality, secret),
      operationTolerance: aesEncryptString(rawCargo.operationTolerance, secret),
      shippingContract: aesEncryptString(rawCargo.shippingContract, secret),
      options: aesEncryptString(rawCargo.options, secret),
      deliveryTerms: aesEncryptString(rawCargo.deliveryTerms, secret),
      gtc: aesEncryptString(rawCargo.gtc, secret),
      pricing: aesEncryptString(rawCargo.pricing, secret),
      shippingConstraint: aesEncryptString(rawCargo.shippingConstraint, secret),
      loadingRangeStart: aesEncryptDate(rawCargo.loadingRangeStart, secret),
      loadingRangeEnd: aesEncryptDate(rawCargo.loadingRangeEnd, secret),
      benchmark: aesEncryptString(rawCargo.benchmark, secret),
    };

    // encapsulate key for target clients
    // const { targetClients } = rawCargo;
    // const buyersPublicKeys = targetClients.map(trader => trader.pk);
    const sellerPublicKey = this.props.userRsaKey;
    // create TradeProposition document
    const tradeProposition = {
      type: TRADE_PROPOSITION_TYPE,
      id: genUid(),
      timestamp: new Date().getTime(),
      // buyersEncapsulatedKey: buyersPublicKeys.map(pk => rsaEncryptAesKey(secret, pk)),
      sellerEncapsulatedKey: rsaEncryptAesKey(secret, sellerPublicKey),
      // buyers: targetClients.map(trader => rsaEncryptUid(trader.id, sellerPublicKey)),
      seller: aesEncryptString(this.props.userId, secret),
      cargo: aesEncryptString(rawCargo.id, secret),
      priceFrom: aesEncryptFloat(rawCargo.minPrice, secret),
      priceTo: aesEncryptFloat(rawCargo.maxPrice, secret),
      operationTolerance: aesEncryptString(rawCargo.operationTolerance, secret),
      options: aesEncryptString(rawCargo.options, secret),
      deliveryTerms: aesEncryptString(rawCargo.deliveryTerms, secret),
      gtc: aesEncryptString(rawCargo.gtc, secret),
    };

    return { cargo, tradeProposition };
  };

  handleChange = evt => {
    this.setState({ cargo: { ...this.state.cargo, [evt.target.name]: evt.target.value } });
  };

  handleSelect = (name, option) => {
    this.setState({ cargo: { ...this.state.cargo, [name]: option } });
  };

  toggleShowCalendar = (isStart, date) => {
    const { showStartCalendar, showEndCalendar } = this.state;
    if (isStart) {
      this.setState({
        showStartCalendar: !showStartCalendar,
        cargo: { ...this.state.cargo, loadingRangeStart: date },
      });
    } else {
      this.setState({
        showEndCalendar: !showEndCalendar,
        cargo: { ...this.state.cargo, loadingRangeEnd: date },
      });
    }
  };

  addCargo = () => {
    const { cargo, cap } = this.state;
    console.log('cargo :>> ', cargo);
    const shippingConstraint =
      this.state.cargo.cargoSize < 500000
        ? shippingConstraintOptions[0].value
        : this.state.cargo.cargoSize < 1500000
        ? shippingConstraintOptions[1].value
        : shippingConstraintOptions[2].value;
    const newCargo = {
      id: cargo.id,
      name: cargo.name,
      grade: cargo.grade.key,
      minPrice: cargo.minPrice,
      maxPrice: cargo.maxPrice,
      cargoSize: cargo.cargoSize,
      quality: cargo.quality,
      operationTolerance: cargo.operationTolerance.key,
      shippingContract: cargo.shippingContract.key,
      deliveryTerms: cargo.deliveryTerms.key,
      options: cargo.options.key,
      gtc: cargo.gtc.key,
      pricing: cargo.pricing.key,
      shippingConstraint: shippingConstraint,
      loadingRangeStart: new Date(cargo.loadingRangeStart),
      loadingRangeEnd: new Date(cargo.loadingRangeEnd),
      benchmark: cargo.benchmark.key,
      targetClients: cargo.targetClients,
    };
    cap.push(newCargo);
    console.log('cap :>> ', cap);
    window.scrollTo(0, 0);
    this.setState({ cargo: { ...initialCargoState(), grade: cargo.grade }, cap });
  };

  submitCap = async () => {
    const { cap } = this.state;
    const docs = [];
    for (const rawCargo of cap) {
      const { cargo, tradeProposition } = this.marshalCargo(rawCargo);
      docs.push(cargo);
      docs.push(tradeProposition);
    }
    console.log('docs :>> ', docs);
    await postToDB(docs); // TODO we may need not to await
    this.setState({ isSubmitting: false });
    sendAuditLogEntry('New Cargo');
    this.props.history.push('/');
  };

  render() {
    const {
      name,
      quality,
      cargoSize,
      grade,
      options,
      operationTolerance,
      shippingContract,
      deliveryTerms,
      gtc,
      pricing,
      loadingRangeStart,
      loadingRangeEnd,
      minPrice,
      maxPrice,
      benchmark,
      // targetClients,
    } = this.state.cargo;

    const { cap, showStartCalendar, showEndCalendar } = this.state;

    const isFormValid =
      name &&
      grade &&
      cargoSize &&
      quality &&
      options &&
      shippingContract &&
      operationTolerance &&
      deliveryTerms &&
      gtc &&
      pricing &&
      loadingRangeStart &&
      loadingRangeEnd &&
      minPrice &&
      maxPrice &&
      minPrice <= benchmarkPrice(grade.key) &&
      maxPrice >= benchmarkPrice(grade.key) &&
      benchmark;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box className={styles['new-cap-page']}>
            <Heading
              level={size !== 'large' ? 3 : 2}
              margin={{
                top: size !== 'large' ? 'medium' : 'large',
                left: 'large',
              }}
            >
              {'New CARGO'}
            </Heading>
            <Box>
              {cap &&
                cap.map((cargo, idx) => {
                  return (
                    <Box
                      background={'panel'}
                      width={size !== 'large' ? '650px' : '800px'}
                      margin={{ top: 'small', horizontal: 'auto' }}
                      pad={{ vertical: 'small', horizontal: size !== 'large' ? 'medium' : 'large' }}
                      key={idx}
                      direction="row"
                      justify="between"
                      align="center"
                    >
                      <Box direction="row" align="baseline">
                        <Heading level={size !== 'large' ? 4 : 3} margin={{ right: 'small' }}>
                          {cargo.name}
                        </Heading>
                        <Text
                          size={size !== 'large' ? '15px' : '20px'}
                        >{`${cargo.minPrice}$ - ${cargo.maxPrice}$`}</Text>
                      </Box>
                      <Box width="180px" height="40px">
                        <Button
                          primary
                          color="danger"
                          fill="vertical"
                          label={
                            <Text color="white" size="13px" weight="bold" textAlign="center">
                              {'DELETE'}
                            </Text>
                          }
                          onClick={() =>
                            this.setState({
                              cap: cap.filter((_, i) => i !== idx),
                            })
                          }
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box
              background={'panel'}
              width={size !== 'large' ? '650px' : '800px'}
              margin={{ top: 'small', horizontal: 'auto' }}
              pad={{ vertical: size !== 'large' ? '10px' : 'small', horizontal: 'large' }}
            >
              <Heading level={size !== 'large' ? 3 : 2}>{'New cargo'}</Heading>
              <NameGrade
                name={name}
                grade={grade}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                disableGrade={cap && cap.length > 0}
              />
              {/* <TargetClients targetClients={targetClients} handleSelect={this.handleSelect} /> */}
              <SizeQuality
                cargoSize={cargoSize}
                quality={quality}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
              />
              <OperationTolerance
                operationTolerance={operationTolerance}
                options={options}
                handleSelect={this.handleSelect}
              />
              <ShippingConstraint cargoSize={cargoSize} handleChange={this.handleChange} />
              <ShippingContract
                shippingContract={shippingContract}
                handleSelect={this.handleSelect}
              />
              <LoadingRange
                loadingRangeStart={loadingRangeStart}
                loadingRangeEnd={loadingRangeEnd}
                showStartCalendar={showStartCalendar}
                showEndCalendar={showEndCalendar}
                toggleShowCalendar={this.toggleShowCalendar}
              />
              <DeliveryTerms deliveryTerms={deliveryTerms} handleSelect={this.handleSelect} />
              <GTC gtc={gtc} handleSelect={this.handleSelect} />
              <Pricing pricing={pricing} handleSelect={this.handleSelect} />
              <BenchmarkRefinery benchmark={benchmark} handleSelect={this.handleSelect} />
              <PriceRange
                minPrice={minPrice}
                maxPrice={maxPrice}
                grade={grade}
                handleChange={this.handleChange}
              />
              <Box alignSelf="end" width="200px" height="60px" margin={{ vertical: 'medium' }}>
                <Button
                  primary
                  color="accent-1"
                  fill="vertical"
                  label={
                    <Text color="white" size="13px" weight="bold" textAlign="center">
                      {'ADD CARGO'}
                    </Text>
                  }
                  disabled={!isFormValid}
                  onClick={this.addCargo}
                />
              </Box>
            </Box>
            <Box margin={{ horizontal: 'auto', vertical: 'medium' }} height="60px" width="350px">
              <Button
                primary
                color={'success'}
                fill="vertical"
                label={
                  <Text color={'white'} size="13px" weight="bold" textAlign="center">
                    {'SUBMIT CRUDE AVAILABILITY PROGRAM'}
                  </Text>
                }
                disabled={
                  !cap || cap.length === 0 || this.props.isWriting || this.state.isSubmitting
                }
                onClick={() => {
                  this.setState({ isSubmitting: true }, this.submitCap);
                }}
              />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

NewCap.propTypes = {
  userId: PropTypes.string.isRequired,
  userRsaKey: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isWriting: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    userId: store.user.id,
    userRsaKey: store.user.rsaKey,
    isWriting: store.db.isWriting,
  };
};

export default connect(mapStateToProps)(withRouter(NewCap));
