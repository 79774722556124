import {
  PARSE_FILES_CONTENT,
  SET_IPFS_DIR_HASH,
  SET_IPFS_FILES,
  SET_IS_WRITING,
  SET_NEW_FETCHED_CIDS,
} from '../actions/dbActions';
import {
  BID_TYPE,
  SALE_TYPE,
  TRADE_PROPOSITION_TYPE,
  TRADER_TYPE,
  ALL_RSA_KEYS,
} from '../util/constants';
import NodeRSA from 'node-rsa';
import { rsaDecryptAesKey } from '../util/rsa';
// import { rsaDecryptUid } from '../util/rsa';
import { aesDecryptDate, aesDecryptFloat, aesDecryptInt, aesDecryptString } from '../util/aes';

const initialState = {
  isWriting: false,
  ipfsDirPath: '',
  cidToNames: {},
  ipfsFilesContent: {},
  ipfsFilesIds: [],
  docs: {},
  traderIds: [],
  tradePropsAsSellerIds: [],
  tradePropsAsBuyerIds: [],
  cargoIds: [],
  bidIds: [],
  saleIds: [],
};

const dbReducer = (state = initialState, action) => {
  let cidToNames;
  const ipfsFilesContent = Object.assign({}, state.ipfsFilesContent);
  const docs = {};
  let rsaKey;
  const traderIds = [];
  let rsaPrivateKey;
  let aesKey;
  let aesKeyDecrypted;
  let isSeller;
  const tradePropsAsSellerIds = [];
  const tradePropsAsBuyerIds = [];
  const cargoIds = [];
  const bidIds = [];
  const saleIds = [];
  switch (action.type) {
    case SET_NEW_FETCHED_CIDS:
      cidToNames = Object.assign({}, state.cidToNames, action.payload.newCidsToNames);
      return Object.assign({}, state, { cidToNames });
    case SET_IS_WRITING:
      return Object.assign({}, state, { isWriting: action.payload.isWriting });
    case SET_IPFS_DIR_HASH:
      return Object.assign({}, state, { ipfsDirPath: action.payload.ipfsDirHash });
    case SET_IPFS_FILES:
      for (const file of action.payload.newFiles) {
        ipfsFilesContent[file.name] = file.content;
      }
      return Object.assign({}, state, {
        ipfsFilesContent,
        ipfsFilesIds: action.payload.filesIds,
      });
    case PARSE_FILES_CONTENT:
      // parse all files
      for (const fileContent of action.payload.filesContent) {
        rsaPrivateKey = action.payload.rsaPrivateKey;
        // parse content
        const doc = JSON.parse(fileContent);
        // update index arrays
        switch (doc.type) {
          case TRADER_TYPE:
            rsaKey = new NodeRSA();
            rsaKey.importKey(doc.pk, 'public');
            docs[doc.id] = Object.assign({}, doc, { pk: rsaKey });
            traderIds.push(doc.id);
            break;
          case SALE_TYPE:
            // decrypt Bid
            docs[doc.id] = doc;
            saleIds.push(doc.id);
            break;
          case BID_TYPE:
            if (!rsaPrivateKey) continue;
            aesKeyDecrypted = false;
            // try to decrypt encapsulatedKeys
            for (const encryptedKey of doc.encapsulatedKeys) {
              try {
                aesKey = rsaDecryptAesKey(encryptedKey, rsaPrivateKey);
                aesKeyDecrypted = true;
                break;
              } catch (e) {
                // probably wrong key, cannot decrypt
              }
            }
            if (!aesKeyDecrypted) continue;
            // decrypt Bid
            docs[doc.id] = {
              type: doc.type,
              id: doc.id,
              tradeProposition: doc.tradeProposition,
              timestamp: doc.timestamp,
              price: aesDecryptFloat(doc.price, aesKey),
              buyer: aesDecryptString(doc.buyer, aesKey),
            };
            bidIds.push(doc.id);
            break;
          case TRADE_PROPOSITION_TYPE:
            if (!rsaPrivateKey) continue;
            aesKeyDecrypted = false;
            // try to decrypt sellerEncapsulatedKey
            try {
              aesKey = rsaDecryptAesKey(doc.sellerEncapsulatedKey, rsaPrivateKey);
              aesKeyDecrypted = true;
              isSeller = true;
            } catch (e) {
              // console.log('e :>> ', e);
              // probably wrong key, cannot decrypt
            }
            if (!aesKeyDecrypted && doc?.sellerEncapsulatedKey) {
              // try to decrypt buyersEncapsulatedKey
              for (const userPrivKey of ALL_RSA_KEYS) {
                try {
                  aesKey = rsaDecryptAesKey(doc.sellerEncapsulatedKey, userPrivKey);
                  aesKeyDecrypted = true;
                  isSeller = false;
                  console.log('Successfully decrypted Aes key.');
                  break;
                } catch (e) {
                  // probably wrong key, cannot decrypt
                }
              }
            }
            if (!aesKeyDecrypted) continue;
            // console.log('aesKey :>> ', aesKey);

            // decrypt TradeProposition
            docs[doc.id] = {
              type: doc.type,
              id: doc.id,
              timestamp: new Date(doc.timestamp),
              unixTimestamp: doc.timestamp,
              encapsulatedKey: aesKey,
              seller: aesDecryptString(doc.seller, aesKey),
              cargo: aesDecryptString(doc.cargo, aesKey),
              priceFrom: aesDecryptFloat(doc.priceFrom, aesKey),
              priceTo: aesDecryptFloat(doc.priceTo, aesKey),
              bidIds: [],
              saleId: null,
              operationTolerance: aesDecryptString(doc.operationTolerance, aesKey),
              options: aesDecryptString(doc.options, aesKey),
              deliveryTerms: aesDecryptString(doc.deliveryTerms, aesKey),
              gtc: aesDecryptString(doc.gtc, aesKey),
            };
            if (isSeller) {
              // decrypt list of buyers
              // try {
              //   buyers = doc.buyers.map(encUid => rsaDecryptUid(encUid, rsaPrivateKey));
              // } catch (e) {
              //   continue;
              // }
              // docs[doc.id].buyers = buyers;
            }
            if (isSeller) {
              tradePropsAsSellerIds.push(doc.id);
            }
            // else {
            //   tradePropsAsBuyerIds.push(doc.id);
            // }
            tradePropsAsBuyerIds.push(doc.id);
            break;
          default:
            break;
        }
      }
      // add Bid IDs to related TradeProps documents
      for (const bidId of bidIds) {
        if (docs[docs[bidId]?.tradeProposition]) {
          docs[docs[bidId]?.tradeProposition].bidIds.push(bidId);
        }
      }
      // add Sale IDs to related TradeProps documents
      for (const saleId of saleIds) {
        if (!docs[docs[saleId].tradeProposition]) continue;
        docs[docs[saleId].tradeProposition].saleId = saleId;
      }
      // decrypt cargos
      for (const tradePropId of [].concat(tradePropsAsSellerIds, tradePropsAsBuyerIds)) {
        const tradeProp = docs[tradePropId];
        const secret = tradeProp.encapsulatedKey;
        const cargoId = tradeProp.cargo;
        const rawCargo = JSON.parse(ipfsFilesContent[cargoId]);
        docs[cargoId] = {
          type: rawCargo.type,
          id: rawCargo.id,
          name: aesDecryptString(rawCargo.name, secret),
          grade: aesDecryptString(rawCargo.grade, secret),
          cargoSize: aesDecryptInt(rawCargo.cargoSize, secret),
          quality: aesDecryptString(rawCargo.quality, secret),
          shippingConstraint: aesDecryptString(rawCargo.shippingConstraint, secret),
          loadingRangeStart: aesDecryptDate(rawCargo.loadingRangeStart, secret),
          loadingRangeEnd: aesDecryptDate(rawCargo.loadingRangeEnd, secret),
          benchmark: aesDecryptString(rawCargo.benchmark, secret),
          // refinery: aesDecryptString(rawCargo.refinery, secret),
          encapsulatedKey: tradeProp.encapsulatedKey,
          operationTolerance: aesDecryptString(rawCargo.operationTolerance, secret),
          options: aesDecryptString(rawCargo.options, secret),
          deliveryTerms: aesDecryptString(rawCargo.deliveryTerms, secret),
          gtc: aesDecryptString(rawCargo.gtc, secret),
        };
        cargoIds.push(cargoId);
      }
      return Object.assign({}, state, {
        docs,
        traderIds,
        tradePropsAsSellerIds,
        tradePropsAsBuyerIds,
        cargoIds,
        bidIds,
        saleIds,
      });
    default:
      return state;
  }
};
export default dbReducer;
