import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Text, ResponsiveContext } from 'grommet';
import styles from '../Trading.module.scss';
import { connect } from 'react-redux';
import { postToDB, postToDBDismiss } from '../../../util/postToDB';
import { SALE_TYPE } from '../../../util/constants';
import { genUid } from '../../../util/util';
import { rsaEncryptUid } from '../../../util/rsa';
import { sendAuditLogEntry } from '../../../util/axios';

class Sell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelling: false,
      disabledButtons: {},
    };
  }

  // submitBidDismiss = (bidId, bid) => {
  //   console.log('this.disabledButtons dismiss:>> ', this.state.disabledButtons);
  //   this.setState({
  //     disabledButtons: { ...this.state.disabledButtons, [bid?.id]: true },
  //   });
  //   const { userId, dismissedBids, rsaKey, ipfsFilesContent, resetCurrentSale } = this.props;
  //   const currentUserDoc = JSON.parse(ipfsFilesContent[userId]);
  //   const currentDismissedBids = dismissedBids.slice(0);
  //   currentDismissedBids.push(bidId);
  //   const newUserDoc = Object.assign({}, currentUserDoc, {
  //     dismissedBids: currentDismissedBids.map(id => rsaEncryptUid(id, rsaKey)),
  //   });
  //   resetCurrentSale();
  //   console.log('[newUserDoc] :>> ', [newUserDoc]);
  //   postToDB([newUserDoc]);
  //   sendAuditLogEntry('DISMISS');
  // };

  submitBidDismiss = (bidId, bid) => {
    console.log('this.disabledButtons dismiss:>> ', this.state.disabledButtons);
    this.setState({
      disabledButtons: { ...this.state.disabledButtons, [bid?.id]: true },
    });
    const { userId, dismissedBids, rsaKey, ipfsFilesContent, resetCurrentSale } = this.props;
    const currentUserDoc = JSON.parse(ipfsFilesContent[userId]);
    const currentDismissedBids = dismissedBids.slice(0);
    currentDismissedBids.push(bidId);
    const newUserDoc = Object.assign({}, currentUserDoc, {
      dismissedBids: currentDismissedBids.map(id => rsaEncryptUid(id, rsaKey)),
    });
    resetCurrentSale();
    postToDBDismiss([newUserDoc], bidId);
    sendAuditLogEntry('DISMISS');
  };

  marshalSale = bidId => {
    const { docs } = this.props;

    const bid = docs[bidId];
    const tradeProp = docs[bid.tradeProposition];
    const cargo = docs[tradeProp.cargo];

    // marshal and return
    return {
      type: SALE_TYPE,
      id: genUid(),
      tradeProposition: bid.tradeProposition,
      timestamp: new Date().getTime(),
      grade: cargo.grade,
      cargoSize: cargo.cargoSize,
      quality: cargo.quality,
      shippingConstraint: cargo.shippingConstraint,
      loadingRangeStart: cargo.loadingRangeStart,
      loadingRangeEnd: cargo.loadingRangeEnd,
      price: bid.price,
      refinery: cargo.refinery,
      seller: tradeProp.seller,
      buyer: bid.buyer,
    };
  };

  submitSell = (bidId, bid) => {
    this.setState(
      {
        isSelling: true,
        disabledButtons: { ...this.state.disabledButtons, [bid?.tradeProposition]: true },
      },
      () => {
        const newDoc = this.marshalSale(bidId);
        postToDB([newDoc]);
        sendAuditLogEntry('SELL');
      },
    );
  };

  render() {
    const { dismissedBids, docs, currentSale, ipfsNames } = this.props;

    const { disabledButtons } = this.state;

    const tradeProp = docs[currentSale.id];
    const { bidIds } = tradeProp;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box pad={{ vertical: 'small', horizontal: 'medium' }} height="100%">
            <Box align="baseline" direction="row" justify="between">
              <Box direction="row" align="center">
                <Heading level={3} margin={{ top: 'small' }}>
                  Sell a product
                </Heading>
              </Box>
            </Box>
            <Box height="100%" className={styles.offers}>
              <Box direction="row">
                <Box width="30%">
                  <Text
                    size="17px"
                    color="thead"
                    weight="normal"
                    margin={{ bottom: size !== 'large' ? '5px' : 'medium' }}
                  >
                    Bidder
                  </Text>
                </Box>
                <Box width="30%">
                  <Text
                    size="17px"
                    color="thead"
                    weight="normal"
                    margin={{ bottom: size !== 'large' ? '5px' : 'medium' }}
                  >
                    Price
                  </Text>
                </Box>
              </Box>
              <React.Fragment>
                {bidIds
                  .filter(id => !dismissedBids.includes(id))
                  .map(bidId => {
                    const bid = docs[bidId];
                    // console.log('bid :>> ', bid);
                    console.log('disabledButtons :>> ', disabledButtons);
                    const buyer = docs[bid.buyer];
                    return (
                      <Box
                        direction="row"
                        align="baseline"
                        key={bidId}
                        className={styles.offer}
                        pad={{ vertical: size !== 'large' ? '0px' : 'small' }}
                      >
                        <Box width="30%">
                          <Text
                            size="16px"
                            color="white"
                            weight="bold"
                            margin={{ bottom: size !== 'large' ? '5px' : 'medium' }}
                          >
                            {buyer.name}
                          </Text>
                        </Box>
                        <Box width="30%">
                          <Text
                            size="16px"
                            color="white"
                            weight="bold"
                            margin={{ bottom: size !== 'large' ? '5px' : 'medium' }}
                          >
                            {`${bid.price} $US`}
                          </Text>
                        </Box>
                        <Box width="40%" direction="row" align="center" justify="evenly">
                          <Box height="30px" width="130px" pad={{ right: '5px' }}>
                            <Button
                              fill
                              color="danger"
                              primary
                              // disabled={isWriting || isSelling}
                              disabled={
                                disabledButtons[bid?.tradeProposition] || disabledButtons[bid?.id]
                              }
                              className={styles['sell-buttons']}
                              onClick={() => {
                                this.submitSell(bidId, bid);
                              }}
                            >
                              <Text size="14px" weight="bold">
                                SELL
                              </Text>
                            </Button>
                          </Box>
                          <Box height="30px" width="100px" pad={{ left: '5px' }}>
                            <Button
                              fill
                              color="gray"
                              primary
                              disabled={
                                disabledButtons[bid?.tradeProposition] || disabledButtons[bid?.id]
                              }
                              // disabled={isWriting || isSelling}
                              className={styles['sell-buttons']}
                              onClick={() => {
                                this.submitBidDismiss(bidId, bid);
                              }}
                            >
                              <Text size="14px" weight={'bold'} color={'white'}>
                                DISMISS
                              </Text>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </React.Fragment>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

Sell.propTypes = {
  userId: PropTypes.string.isRequired,
  dismissedBids: PropTypes.arrayOf(PropTypes.string),
  rsaKey: PropTypes.object.isRequired,
  currentSale: PropTypes.object.isRequired,
  docs: PropTypes.objectOf(PropTypes.object),
  ipfsFilesContent: PropTypes.object.isRequired,
  resetCurrentSale: PropTypes.func.isRequired,
  isWriting: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    userId: store.user.id,
    dismissedBids: store.user.dismissedBids,
    rsaKey: store.user.rsaKey,
    docs: store.db.docs,
    ipfsFilesContent: store.db.ipfsFilesContent,
    isWriting: store.db.isWriting,
    ipfsNames: store.db.ipfsNames,
  };
};

export default connect(mapStateToProps)(Sell);
