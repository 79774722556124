import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Calendar, Collapsible, FormField, ResponsiveContext } from 'grommet';

const LoadingRange = ({
  loadingRangeStart,
  showStartCalendar,
  loadingRangeEnd,
  showEndCalendar,
  toggleShowCalendar,
  formLabel,
}) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: 'medium' }}>
          <Box>
            <Box width="100%">
              <FormField label={formLabel || 'Loading range'}>
                <Box direction="row" justify="evenly">
                  <Box width="50%">
                    <Box size="300px" style={{ position: 'relative' }}>
                      <Button
                        color={'primary'}
                        onClick={() => toggleShowCalendar(true, '')}
                        label={
                          loadingRangeStart
                            ? new Date(loadingRangeStart).toLocaleDateString()
                            : 'Start date'
                        }
                        plain
                        style={{
                          background: 'white',
                          height: size !== 'large' ? '30px' : '50px',
                          paddingLeft: '15px',
                          width: size !== 'large' ? '250px' : '300px',
                        }}
                      />
                      <Box
                        background={'primary'}
                        style={{ position: 'absolute', zIndex: 3, top: '50px' }}
                      >
                        <Collapsible open={showStartCalendar}>
                          <Calendar
                            date={loadingRangeStart}
                            daysOfWeek
                            onSelect={date => toggleShowCalendar(true, date)}
                            size="small"
                            disabled={loadingRangeEnd ? [[loadingRangeEnd, '3000-01-01']] : []}
                            bounds={['2000-01-01', '3000-01-01']}
                          />
                        </Collapsible>
                      </Box>
                    </Box>
                  </Box>
                  <Box width="50%">
                    <Box size="300px" style={{ position: 'relative' }}>
                      <Button
                        color={'primary'}
                        onClick={() => toggleShowCalendar(false, '')}
                        label={
                          loadingRangeEnd
                            ? new Date(loadingRangeEnd).toLocaleDateString()
                            : 'End date'
                        }
                        plain
                        style={{
                          background: 'white',
                          height: size !== 'large' ? '30px' : '50px',
                          paddingLeft: '15px',
                          width: size !== 'large' ? '250px' : '300px',
                        }}
                      />
                      <Box
                        background={'primary'}
                        style={{ position: 'absolute', zIndex: 3, top: '50px' }}
                      >
                        <Collapsible open={showEndCalendar}>
                          <Calendar
                            date={loadingRangeEnd}
                            daysOfWeek
                            onSelect={date => toggleShowCalendar(false, date)}
                            size="small"
                            disabled={loadingRangeStart ? [['2000-01-01', loadingRangeStart]] : []}
                            bounds={['2000-01-01', '3000-01-01']}
                          />
                        </Collapsible>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

LoadingRange.propTypes = {
  loadingRangeStart: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  showStartCalendar: PropTypes.bool.isRequired,
  loadingRangeEnd: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  showEndCalendar: PropTypes.bool.isRequired,
  formLabel: PropTypes.string,
  toggleShowCalendar: PropTypes.func.isRequired,
};

export default LoadingRange;
