import axios from 'axios';
import { store } from '../index';
import {
  parseFilesContent,
  setIpfsFiles,
  setIsWriting,
  setNewCidToNames,
} from '../actions/dbActions';
import { IPNS_KEY_NAME } from './constants';
import { setIpfsNames } from '../actions/ipfsNamesActions';

const loadAllFilePaths = async () => {
  var response;
  try {
    response = await axios.get(`/ipfs/name/${IPNS_KEY_NAME}`);
  } catch (err) {
    console.log('Error occurred :>> ', err);
    return;
  }

  if (!response) {
    return;
  }

  const { db, user } = store.getState();
  const existDbFiles = db.ipfsFilesIds;

  const existingIpfs = response.data.content;
  const existingIpfsObj = JSON.parse(existingIpfs);
  if (existingIpfsObj.length <= 6) {
    return;
  }

  const ipfsRequests = [];
  let ipfsResponse = [];
  // existingIpfsObj.slice(6).forEach(ipfs => {
  existingIpfsObj.forEach(ipfs => {
    if (existDbFiles.indexOf(ipfs) !== -1) {
      return;
    }
    ipfsRequests.push(axios.get(`/ipfs/${ipfs}`));
  });

  await Promise.all(ipfsRequests)
    .then(result => {
      ipfsResponse = result.map(x => x.data);
    })
    .catch(err => {
      console.log('err :>> ', err);
    });

  const newFiles = [];
  const newCidToNames = {};
  const allIpfsPaths = {};
  for (let i = 0; i < ipfsResponse.length; i++) {
    const filePath = ipfsResponse[i].name;
    const data = ipfsResponse[i];
    const { name, content, ipfsPath } = data;
    allIpfsPaths[name] = ipfsPath;
    newFiles.push({ name, content });
    newCidToNames[filePath] = name;
  }

  setIpfsNames(allIpfsPaths);
  localStorage.setItem('ipfsNames', JSON.stringify(allIpfsPaths));

  // store raw files
  // const filesIds = ipfsResponse.map(cid => db.cidToNames[cid] || newCidToNames[cid]);

  const newCidFiles = Object.keys(newCidToNames);
  for (const existFile of existDbFiles) {
    if (newCidFiles.indexOf(existFile) === -1) {
      newCidFiles.push(existFile);
    }
  }
  const filesIds = [...newCidFiles];
  // const filesIds = Object.keys(newCidToNames);
  store.dispatch(setIsWriting(true));

  store.dispatch(setIpfsFiles(filesIds, newFiles));
  store.dispatch(setNewCidToNames(newCidToNames));

  // parse and store clear documents
  const filesContent = [];
  for (const id of filesIds) {
    if (db.ipfsFilesContent[id]) {
      filesContent.push(db.ipfsFilesContent[id]);
    } else {
      const filteredNewFiles = newFiles.filter(f => f.name === id);
      if (filteredNewFiles.length < 1) continue;
      filesContent.push(filteredNewFiles[0].content);
    }
  }

  const rsaPrivateKey = user.rsaKey;

  await store.dispatch(parseFilesContent(filesContent, rsaPrivateKey));

  store.dispatch(setIsWriting(false));

  await axios.get(`/user/profile`);
  // const userHardCodedIdx = user.hardCodedIdx;
  // if (userHardCodedIdx >= 0) {
  //   const { rsaKey, dismissedBids, id } = getUserDetails(userHardCodedIdx);
  //   store.dispatch(setUser(rsaKey, dismissedBids, id, userHardCodedIdx));
  // }
};

export { loadAllFilePaths };
