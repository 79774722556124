import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Text, TextInput, ResponsiveContext } from 'grommet';
import styles from '../Trading.module.scss';
import { postToDB } from '../../../util/postToDB';
import { aesEncryptFloat, aesEncryptString, generateAesKey } from '../../../util/aes';
import { BID_TYPE } from '../../../util/constants';
import { genUid } from '../../../util/util';
import { rsaEncryptAesKey } from '../../../util/rsa';
import { connect } from 'react-redux';
import { sendAuditLogEntry } from '../../../util/axios';

class Bid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0.0,
      isBidding: false,
    };
  }

  marshalBid = (tradePropId, price) => {
    const { docs, userId } = this.props;
    // generate a symmetric key
    const secret = generateAesKey();

    // encapsulate this key
    const encapsulatedKeys = [];
    encapsulatedKeys.push(rsaEncryptAesKey(secret, docs[userId].pk));
    encapsulatedKeys.push(rsaEncryptAesKey(secret, docs[docs[tradePropId].seller].pk));

    // marshal and return
    return {
      type: BID_TYPE,
      id: genUid(),
      tradeProposition: tradePropId,
      timestamp: new Date().getTime(),
      encapsulatedKeys,
      price: aesEncryptFloat(price, secret),
      buyer: aesEncryptString(userId, secret),
      // salt: 'TODO',
      // clientToSignature: 'TODO',
    };
  };

  submitBid = () => {
    const { currentBid, resetCurrentBid } = this.props;
    const { price } = this.state;
    // post new doc
    const tradePropId = currentBid.id;
    const newDoc = this.marshalBid(tradePropId, price);
    postToDB([newDoc], 'bid');

    // reset front
    resetCurrentBid();
    this.setState({ price: 0.0, isBidding: false });
    sendAuditLogEntry('BID');
  };

  render() {
    const { currentBid, isWriting } = this.props;
    const { price } = this.state;
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box pad={{ vertical: 'small', horizontal: 'medium' }} height="100%">
            <Box align="baseline" direction="row" justify="between">
              <Box direction="row" align="center">
                <Heading
                  level={size !== 'large' ? 4 : 3}
                  margin={{ top: size !== 'large' ? '5px' : 'small' }}
                >
                  Bid
                </Heading>
              </Box>
            </Box>
            <Box height="100%" justify="between">
              <Box direction="row" justify="between">
                <Box direction="row" margin={{ bottom: 'medium' }} align="center" width="50%">
                  <Text
                    size={size !== 'large' ? '13px' : '17px'}
                    color="thead"
                    weight="normal"
                    margin={{ right: size !== 'large' ? '5px' : 'large' }}
                  >
                    Balance
                  </Text>
                  <Text weight="bold" size={size !== 'large' ? '12px' : '16px'}>
                    {currentBid ? `${currentBid.price[0]} - ${currentBid.price[1]} $US` : `N/A`}
                  </Text>
                </Box>
                <Box direction="row" margin={{ bottom: 'medium' }} align="center" width="50%">
                  <Text
                    size={size !== 'large' ? '13px' : '17px'}
                    color="thead"
                    weight="normal"
                    margin={{ right: size !== 'large' ? 'small' : 'large' }}
                  >
                    Price Differential
                  </Text>
                  <span className={styles['price-input']}>
                    <TextInput
                      disabled={!currentBid}
                      type="number"
                      className={`${styles['bid-input']} ${size !== 'large' &&
                        styles['small-screen']}`}
                      onChange={e => this.setState({ price: parseFloat(e.target.value) })}
                      value={price || '0'}
                      min={currentBid ? currentBid.price[0] : undefined}
                      max={currentBid ? currentBid.price[1] : undefined}
                      step="0.001"
                    />
                    <span>$/bbl</span>
                  </span>
                </Box>
              </Box>
              <Box height="37px">
                <Button
                  fill
                  color={!price ? 'disabled' : 'success'}
                  primary
                  disabled={
                    !price ||
                    price < currentBid.price[0] ||
                    price > currentBid.price[1] ||
                    isWriting
                  }
                  className={styles['ask-bid-btn']}
                  onClick={() => {
                    this.setState({ isBidding: true }, this.submitBid);
                  }}
                >
                  <Text size="14px" weight="bold" color="white">
                    BID
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

Bid.propTypes = {
  currentBid: PropTypes.object,
  resetCurrentBid: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  docs: PropTypes.objectOf(PropTypes.object),
  isWriting: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    userId: store.user.id,
    docs: store.db.docs,
    isWriting: store.db.isWriting,
  };
};

export default connect(mapStateToProps)(Bid);
