import React, { useState } from 'react';
import { Box, Grid, ResponsiveContext } from 'grommet';
import Sales from './Areas/Sales';
import Graphs from './Areas/Graphs';
import Products from './Areas/Products';
import Ask from './Areas/Ask';
import Watchlist from './Areas/Watchlist';
import Bid from './Areas/Bid';
import Sell from './Areas/Sell';

const Trading = () => {
  const [currentAsk, setCurrentAsk] = useState(null);
  const [currentBid, setCurrentBid] = useState(null);
  const [currentSale, setCurrentSale] = useState(null);
  function resetCurrentSale() {
    setCurrentSale(null);
  }
  function resetCurrentAsk() {
    setCurrentAsk(null);
  }
  function resetCurrentBid() {
    setCurrentBid(null);
  }
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: 'medium', horizontal: 'medium' }}>
          <Grid
            rows={[
              size !== 'large' ? '150px' : '170px',
              size !== 'large' ? '160px' : '0px',
              size !== 'large' ? '0px' : 'flex',
              size !== 'large' ? '200px' : '300px',
            ]}
            columns={['flex', '40%', 'flex']}
            gap="medium"
            fill
            areas={[
              { name: 'watchlist', start: [0, 0], end: [0, 3] },
              { name: 'buy', start: [1, 0], end: [1, 0] },
              { name: 'graph', start: [1, 1], end: [1, size !== 'large' ? 2 : 3] },
              { name: 'sell', start: [1, size !== 'large' ? 2 : 3], end: [1, 3] },
              { name: 'products', start: [2, 0], end: [2, 2] },
              { name: 'sales', start: [2, 2], end: [2, 3] },
            ]}
          >
            <Box background={'panel'} gridArea="watchlist">
              <Watchlist
                setCurrentBid={setCurrentBid}
                setCurrentSale={setCurrentSale}
                setCurrentAsk={setCurrentAsk}
                selected={currentBid}
              />
            </Box>
            <Box background={'panel'} gridArea="buy">
              <Bid currentBid={currentBid} resetCurrentBid={resetCurrentBid} />
            </Box>
            <Graphs />
            <Box background={'panel'} gridArea="sell">
              {currentSale ? (
                <Sell currentSale={currentSale} resetCurrentSale={resetCurrentSale} />
              ) : (
                <Ask currentAsk={currentAsk} resetCurrentAsk={resetCurrentAsk} />
              )}
            </Box>
            <Box background={'panel'} gridArea="products">
              <Products
                setCurrentBid={setCurrentBid}
                setCurrentAsk={setCurrentAsk}
                setCurrentSale={setCurrentSale}
                selected={currentAsk}
              />
            </Box>
            <Box background={'panel'} gridArea="sales">
              <Sales
                setCurrentBid={setCurrentBid}
                setCurrentSale={setCurrentSale}
                setCurrentAsk={setCurrentAsk}
                selected={currentSale}
              />
            </Box>
          </Grid>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Trading;
