import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TradesTable from '../TradesTable/TradesTable';
import { Box, Button, Collapsible, DropButton, Select, Text, TextInput } from 'grommet';
import styles from './TradingHistory.module.scss';
import { Close, Filter, Search } from 'grommet-icons';
import {
  benchmarkDictionnary,
  benchmarkOptions,
  gradeOptions,
  qualityDictionnary,
  qualityOptions,
  refineryDictionnary,
  // shippingConstraintDictionnary,
} from '../../util/formFieldsUtils';
import CargoSizeDrop from './CargoSizeDrop';
import PriceDrop from './PriceDrop';
import { deepCompare } from '../../util/util';

const TradingHistory = ({ docs, saleIds }) => {
  const initialFilter = {
    grade: '',
    cargoSize: [],
    price: [],
    quality: '',
    benchmark: '',
  };
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(initialFilter);
  const { grade, cargoSize, price, quality, benchmark } = filter;
  const [openCSDrop, setOpenCSDrop] = React.useState();
  const [openPriceDrop, setOpenPriceDrop] = React.useState();

  const trades = saleIds
    // .filter(id => {
    //   const sale = docs[id];
    //   const cargo = docs[docs[sale?.tradeProposition]?.cargo];
    //   return cargo !== undefined;
    // })
    .map(id => {
      const sale = docs[id];
      const cargo = docs[docs[sale.tradeProposition].cargo];

      return {
        cargoName: cargo.name,
        operationTolerance: cargo.operationTolerance,
        options: cargo.options,
        gtc: cargo.gtc,
        deliveryTerms: cargo.deliveryTerms,
        txTime: sale.timestamp,
        grade: sale.grade,
        fromTo: [docs[sale.seller].name, docs[sale.buyer].name],
        cargoSize: sale.cargoSize,
        price: sale.price,
        benchmark: benchmarkDictionnary[cargo.benchmark],
        quality: qualityDictionnary[sale.quality],
        loadingRange: [
          (new Date(sale.loadingRangeStart).getTime() / 1000).toFixed(0),
          (new Date(sale.loadingRangeEnd).getTime() / 1000).toFixed(0),
        ],
        // shippingConstraint: shippingConstraintDictionnary[sale.shippingConstraint],
        shippingConstraint: sale.shippingConstraint,
        refinery: refineryDictionnary[sale.refinery],
      };
    })
    .sort((a, b) => b.txTime - a.txTime)
    .filter(s => s.cargoName.includes(search) && deepCompare(s, filter));
  return (
    <Box>
      <Box
        margin={{ vertical: 'medium', horizontal: 'auto' }}
        pad={{ horizontal: 'xlarge' }}
        width="100%"
      >
        <Box className={styles['search-wrapper']} direction="row" align="center" justify="center">
          <Box height="65px" background={'brand'} width="75%">
            <Search />
            <TextInput
              type="text"
              placeholder="Search by name"
              plain
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Box>
          <Box height="65px" pad={{ left: 'small' }}>
            <Button
              primary
              color={'accent-3'}
              fill
              icon={<Filter className={styles['filter-icon']} />}
              label={
                <Text size="20px" color="white">
                  Show all filters
                </Text>
              }
              onClick={() => setShowFilters(!showFilters)}
            />
          </Box>
        </Box>
        <Collapsible open={showFilters}>
          <Box
            direction="row"
            margin={{ top: '25px', horizontal: 'auto' }}
            pad={{ horizontal: 'xlarge' }}
            width="100%"
          >
            <Box
              round="large"
              background={grade ? 'brand' : 'accent-3'}
              height="37px"
              className={styles['filter-select-wrapper']}
            >
              <Select
                plain
                value={grade}
                options={gradeOptions}
                onChange={({ option }) => setFilter({ ...filter, grade: option.lab })}
                labelKey="lab"
                valueKey="val"
                placeholder="Grade"
              />
            </Box>
            <Box
              round="large"
              background={cargoSize[0] && cargoSize[1] ? 'brand' : 'accent-3'}
              height="37px"
              className={styles['filter-drop-wrapper']}
            >
              <DropButton
                plain
                label={
                  !openCSDrop && cargoSize[0] && cargoSize[1] !== 0 ? (
                    <Text size="15px" color="white">
                      {`${cargoSize[0]} - ${cargoSize[1] ? cargoSize[1] : 'N/A'} bbl`}
                    </Text>
                  ) : (
                    <Text className={styles.italic} size="15px" color="white">
                      Cargo size
                    </Text>
                  )
                }
                open={openCSDrop}
                onOpen={() => setOpenCSDrop(true)}
                onClose={() => setOpenCSDrop(false)}
                dropContent={
                  <CargoSizeDrop
                    onClose={() => setOpenCSDrop(false)}
                    filter={filter}
                    cargoSize={cargoSize}
                    setFilter={setFilter}
                  />
                }
                dropProps={{ align: { top: 'bottom' } }}
              />
            </Box>
            <Box
              round="large"
              background={price[0] && price[1] ? 'brand' : 'accent-3'}
              height="37px"
              className={styles['filter-drop-wrapper']}
            >
              <DropButton
                plain
                label={
                  !openPriceDrop && price[0] && price[1] !== 0 ? (
                    <Text size="15px" color="white">
                      {`${price[0]} - ${price[1] ? price[1] : 'N/A'} $/bbl`}
                    </Text>
                  ) : (
                    <Text className={styles.italic} size="15px" color="white">
                      Price
                    </Text>
                  )
                }
                open={openPriceDrop}
                onOpen={() => setOpenPriceDrop(true)}
                onClose={() => setOpenPriceDrop(false)}
                dropContent={
                  <PriceDrop
                    onClose={() => setOpenPriceDrop(false)}
                    filter={filter}
                    price={price}
                    setFilter={setFilter}
                  />
                }
                dropProps={{ align: { top: 'bottom' } }}
              />
            </Box>
            <Box
              round="large"
              background={quality ? 'brand' : 'accent-3'}
              height="37px"
              className={styles['filter-select-wrapper']}
            >
              <Select
                plain
                value={quality}
                options={qualityOptions}
                onChange={({ option }) => setFilter({ ...filter, quality: option.lab })}
                labelKey="lab"
                valueKey="val"
                placeholder="Quality"
              />
            </Box>
            <Box
              round="large"
              background={benchmark ? 'brand' : 'accent-3'}
              height="37px"
              className={styles['filter-select-wrapper']}
            >
              <Select
                plain
                value={benchmark}
                options={benchmarkOptions}
                onChange={({ option }) => setFilter({ ...filter, benchmark: option.lab })}
                labelKey="lab"
                valueKey="val"
                placeholder="Benchmark"
              />
            </Box>
            <Button
              plain
              label={
                <Text color="white" size="13px">
                  Reset filters
                </Text>
              }
              icon={<Close size="13px" />}
              onClick={() => setFilter(initialFilter)}
            />
          </Box>
        </Collapsible>
      </Box>
      <Box>
        <TradesTable trades={trades} personal={false} />
      </Box>
    </Box>
  );
};

TradingHistory.propTypes = {
  docs: PropTypes.objectOf(PropTypes.object),
  saleIds: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = store => {
  return {
    docs: store.db.docs,
    saleIds: store.db.saleIds.filter(
      saleId =>
        store.db.docs[saleId].buyer === store.user.id ||
        store.db.docs[saleId].seller === store.user.id,
    ),
  };
};

export default connect(mapStateToProps)(TradingHistory);
