import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, TextInput } from 'grommet/es6';
import { Close } from 'grommet-icons/es6';
import styles from './TradingHistory.module.scss';

const CargoSizeDrop = ({ onClose, filter, cargoSize, setFilter }) => (
  <Box pad="small">
    <Box direction="row" justify="between" align="center">
      <Heading level={4} margin="small">
        Select cargo size range
      </Heading>
      <Button icon={<Close />} onClick={onClose} />
    </Box>
    <Box direction="row" align="center">
      <TextInput
        plain
        className={styles['filter-input']}
        value={cargoSize[0] || ''}
        onChange={e => setFilter({ ...filter, cargoSize: [e.target.value, cargoSize[1]] })}
        placeholder="Minimum"
        type="number"
        min={0}
      />
      bbl
    </Box>
    <Box direction="row" align="center">
      <TextInput
        plain
        className={styles['filter-input']}
        value={cargoSize[1] || ''}
        onChange={e => setFilter({ ...filter, cargoSize: [cargoSize[0], e.target.value] })}
        placeholder="Maximum"
        type="number"
        min={cargoSize[0] || 0}
      />
      bbl
    </Box>
  </Box>
);

CargoSizeDrop.propTypes = {
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  cargoSize: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default CargoSizeDrop;
