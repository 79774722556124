// import React from 'react';
import { Box, Text } from 'grommet/es6';
import React, { useState } from 'react';
import styles from './Signup.module.scss';
import { connect } from 'react-redux';
import { setLoggedUser } from '../../actions/logggedUserActions';
import PropTypes from 'prop-types';
import { setUser } from '../../actions/userActions';
import { parseFilesContent } from '../../actions/dbActions';
import { USERS } from './../../util/constants';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import 'reactjs-popup/dist/index.css';
import $ from 'jquery';
import { validateSignUpForm, termsPopupContent } from './SignupHelper';
import { showErrorToast, showSuccessToast } from '../../util/toast-service';

const Signup = ({ setUser, setLoggedUser, parseFilesContent, ipfsFilesContent, ipfsFilesIds }) => {
  const [isError] = useState(false);
  const [errorMsg] = useState('');
  const history = useHistory();

  const [signUpForm, setSignUpForm] = useState({
    gender: 'male',
    tradePartner: 'British Petroleum',
    terms: false,
    licenseAttachment: '',
    captchaVal: '',
  });

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  function detectMimeType(b64) {
    var signatures = {
      JVBERi0: 'application/pdf',
      R0lGODdh: 'image/gif',
      R0lGODlh: 'image/gif',
      iVBORw0KGgo: 'image/png',
      '/9j/': 'image/jpg',
    };

    for (var s in signatures) {
      if (b64.substring(0, 50).includes(s)) {
        return signatures[s];
      }
    }
    return null;
  }

  const onFileChange = async event => {
    try {
      console.log('event.target.files :>> ', event.target.files[0]);
      const base64Code = await toBase64(event.target.files[0]);
      const mimeType = detectMimeType(base64Code);

      if (!mimeType) {
        showErrorToast('Invalid file.');
        return;
      }

      setSignUpForm({ ...signUpForm, licenseAttachment: base64Code });
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  function acceptTerms(res) {
    $('#terms-checkbox').prop('checked', false);
    setSignUpForm({ ...signUpForm, terms: false });
    if (res) {
      $('#terms-checkbox').prop('checked', true);
      window.$('#termsModal').modal('toggle');
      setSignUpForm({ ...signUpForm, terms: true });
    }
  }

  function handleChange(ev) {
    setSignUpForm({ ...signUpForm, [ev.target.name]: ev.target.value });
  }

  function handleSelect(ev) {
    setSignUpForm({ ...signUpForm, [ev.target.name]: ev.target.value });
    console.log('ev :>> ', ev);
  }

  async function signup() {
    const isValidate = await validateSignUpForm(signUpForm);
    console.log('signUpForm :>> ', signUpForm);
    if (!isValidate) {
      return;
    }
    const signUpData = { ...signUpForm, fullName: signUpForm?.firstName + signUpForm?.lastName };

    axios.post('/user/signup', signUpData, { validateStatus: () => true }).then(res => {
      if (res.status === 200) {
        showSuccessToast('Your request submitted successfully.');
        history.push('/login');
        return;
      }
      showErrorToast(res?.data?.error || 'Cannot submitted a request.');
      console.log('Error occured: ', res);
    });
  }

  async function login() {
    history.push('/login');
  }

  function recaptcha(value) {
    setSignUpForm({ ...signUpForm, captchaVal: value });
  }

  return (
    <div>
      <Box className={styles.welcomeBox}></Box>
      <Box className={styles.Box}>
        {isError ? (
          <div className={styles.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
        ) : null}

        <form onSubmit={e => e.preventDefault()} className={styles.formWrapper}>
          <h3 className={(styles.Text1, 'text-left')}>Sign Up</h3>
          <br></br>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>First Name</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                type="text"
                name="firstName"
                placeholder="Enter Your First Name"
                onChange={handleChange}
              />
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>Last Name</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                type="text"
                name="lastName"
                placeholder="Enter Your Last Name"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>Gender</Text>
              <br></br>
              <select className={styles.option} onChange={handleSelect} name="gender">
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>Email ID</Text>
              <input
                className={styles.option}
                type="text"
                name="email"
                placeholder="Enter Your Email ID"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>Trade Partner</Text>
              <br></br>
              <select
                onChange={handleSelect}
                className={styles.option}
                name="tradeType"
                placeholder="Choose a trade partner"
              >
                {USERS.map(x => (
                  <option key={x.name} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>Password</Text>
              <input
                className={styles.option}
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
          </div>

          <h2 className={styles.header}>Address</h2>
          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>Street</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                type="text"
                name="street"
                placeholder="Enter Your Street"
                onChange={handleChange}
              />
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>City</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                name="city"
                type="text"
                placeholder="Enter Your City"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>State</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                name="state"
                onChange={handleChange}
                type="text"
                placeholder="Enter Your State"
              />
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>Zip Code</Text>
              <br></br>
              <input
                name="zipcode"
                onChange={handleChange}
                className={styles.inputbox1}
                type="text"
                placeholder="Enter Your Zip Code"
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>Country</Text>
              <br></br>
              <input
                onChange={handleChange}
                name="country"
                className={styles.inputbox1}
                type="text"
                placeholder="Enter Your Country"
              />
            </div>
            <div className={styles.main}>
              <Text className={styles.Text}>Nationality</Text>
              <br></br>
              <input
                className={styles.inputbox1}
                name="nationality"
                onChange={handleChange}
                type="text"
                placeholder="Enter Your Nationality"
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <Text className={styles.Text}>License ID</Text>
              <br></br>
              <input
                name="licenseID"
                onChange={handleChange}
                className={styles.inputbox1}
                type="text"
                placeholder="Enter Your License ID"
              />
            </div>

            <div className={styles.main}>
              <Text className={styles.Text}>Upload Image or pdf</Text>
              <br></br>
              <input type="file" className={'pt-2'} onChange={onFileChange} />
              {/* <button onClick={onFileUpload}>Upload!</button> */}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.main}>
              <a data-toggle="modal" data-target="#termsModal" className={'cursor-pointer'}>
                <input
                  id="terms-checkbox"
                  className={styles.checkbox}
                  onChange={handleChange}
                  name="terms"
                  type="checkbox"
                ></input>
                <label
                  htmlFor="terms-checkbox"
                  className={(styles.Text, styles.terms_conditions, 'pl-2')}
                >
                  Terms & Conditions
                </label>
              </a>
            </div>
            <div className={(styles.main, 'w-auto')}>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  sitekey={'6LfYYiMkAAAAANSOsJLp1rJCG1LHqs-ws8GTl0Q1'}
                  onChange={recaptcha}
                />
              </div>
            </div>
          </div>

          <Box className={styles.signup_box}>
            <button type="submit" onClick={signup} className={styles.signup}>
              Signup
            </button>
            <button type="submit" onClick={login} className={styles.submit}>
              Log in
            </button>
          </Box>
        </form>

        <div
          className="modal fade"
          id="termsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="termsModal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title colorBlack" id="termsModal">
                  Terms and conditions
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body colorBlack"
                dangerouslySetInnerHTML={{ __html: termsPopupContent() }}
              ></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => acceptTerms(false)}
                >
                  Deny
                </button>
                <button type="button" className="btn btn-primary" onClick={() => acceptTerms(true)}>
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

Signup.propTypes = {
  ipfsFilesContent: PropTypes.objectOf(PropTypes.string),
  ipfsFilesIds: PropTypes.arrayOf(PropTypes.string),
  setUser: PropTypes.func.isRequired,
  setLoggedUser: PropTypes.func.isRequired,
  parseFilesContent: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => ({
  setUser: (rsaKey, dismissedBids, id, hardCodedIdx) => {
    dispatch(setUser(rsaKey, dismissedBids, id, hardCodedIdx));
  },
  setLoggedUser: (token, user, session, otherDetails) => {
    dispatch(setLoggedUser(token, user, session, otherDetails));
  },
  parseFilesContent: (files, rsaPrivateKey) => {
    dispatch(parseFilesContent(files, rsaPrivateKey));
  },
});
const mapStateToProps = store => ({
  ipfsFilesContent: store.db.ipfsFilesContent,
  ipfsFilesIds: store.db.ipfsFilesIds,
});
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
