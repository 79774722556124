import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { IPNS_KEY_NAME, USERS, TRADER_TYPE } from '../../util/constants';
import { genUid } from '../../util/util';
import axios from 'axios';
import {
  parseFilesContent,
  setIpfsDirHash,
  setIpfsFiles,
  setNewCidToNames,
} from '../../actions/dbActions';
import PropTypes from 'prop-types';
import styles from './Initializer.module.scss';
import NodeRSA from 'node-rsa';
import genericErrorReturn from '../../util/genericErrorReturn';
import { setIpfsNames } from '../../actions/ipfsNamesActions';

const FullScreenLoader = () => (
  <div className={styles['loader-wrapper']}>
    <Loader type="Grid" color="#0000b0" height={100} width={100} />
  </div>
);

const Initializer = ({
  children,
  setIpfsDirHash,
  tradersLoaded,
  setIpfsFiles,
  parseFilesContent,
}) => {
  useEffect(() => {
    (async () => {
      // fetch IPFS dir hash
      console.log('Fetching IPNS record');
      let ipfsDirHash = '';
      let filesListContent = '';
      try {
        const r = await axios.get(`/ipns/${IPNS_KEY_NAME}`);
        ipfsDirHash = r.data.ipfsPath;
        filesListContent = JSON.parse(r.data.filesListContent);
      } catch (e) {
        console.log('Failed to resolve IPNS record: ', e);
        return;
      }

      // set IPFS dir hash to the store
      setIpfsDirHash(ipfsDirHash);

      // get all files
      console.log('Getting IPFS files');
      var files = [];
      const cidToNames = {};

      const currentAvailableClients = [];
      const currentIpfsPaths = [];
      const allIpfsPaths = {};
      for (const filePath of filesListContent) {
        try {
          const r = await axios.get(`/ipfs/${filePath}`);
          const { data } = r;
          const { name, content, ipfsPath } = data;
          allIpfsPaths[name] = ipfsPath;
          files.push({ name, content });
          cidToNames[filePath] = name;
          currentIpfsPaths.push(filePath);
          currentAvailableClients.push(content);
        } catch (e) {
          console.log('Failed to get documents: ', e);
        }
      }

      console.log('allIpfsPaths :>> ', allIpfsPaths);
      setIpfsNames(allIpfsPaths);
      localStorage.setItem('ipfsNames', JSON.stringify(allIpfsPaths));

      // const getPromises = [];
      // let getPromisesResponse = [];
      // for (const filePath of filesListContent) {
      //   getPromises.push(axios.get(`/ipfs/${filePath}`));
      // }
      // try {
      //   getPromisesResponse = await Promise.all(getPromises);
      // } catch (e) {
      //   console.log('Failed to get documents: ', e);
      //   //return;
      // }
      // for (let i = 0; i < getPromisesResponse.length; i++) {
      //   const r = getPromisesResponse[i];
      //   const filePath = filesListContent[i];
      //   const { data } = r;
      //   const { name, content } = data;
      //   files.push({ name, content });
      //   cidToNames[filePath] = name;
      // }

      await checkClients(currentIpfsPaths, currentAvailableClients);

      // store raw files
      const filesIds = files.map(f => f.name);
      setIpfsFiles(filesIds, files);
      setNewCidToNames(cidToNames);

      // parse and store clear documents
      const filesContent = files.map(f => f.content);
      parseFilesContent(filesContent);
    })();
  }, [parseFilesContent, setIpfsDirHash, setIpfsFiles]);

  if (!tradersLoaded) return <FullScreenLoader />;
  return children;
};

async function checkClients(ipfsPath, availableClient) {
  // return new Promise((resolve, reject) => {
  //   resolve(ipfsPath);
  // });
  const allUsers = USERS.map(x => x.name);
  const userObj = {};
  USERS.forEach(user => {
    userObj[user.name] = user.rsaSecretKey;
  });
  availableClient = availableClient.map(client => {
    return JSON.parse(client).name;
  });
  const missedClients = [];
  allUsers.forEach(client => {
    if (availableClient.indexOf(client) === -1) {
      missedClients.push(client);
    }
  });
  // console.log('ipfsPath :>> ', ipfsPath);
  // console.log('userObj :>> ', userObj);
  // console.log('missedClients :>> ', missedClients);
  const newPushedFilePaths = [];
  if (missedClients.length > 0) {
    const pushedClients = await pushClientsToDB(missedClients, userObj);
    newPushedFilePaths.push(...pushedClients);
  } else {
    return;
  }
  // console.log('newPushedFilePaths :>> ', newPushedFilePaths);

  const allIpfsPaths = ipfsPath.concat(newPushedFilePaths);
  // console.log(`Publishing IPNS record: {keyName : ${IPNS_KEY_NAME}}`);
  try {
    const { data } = await axios.post('/ipfs', {
      name: IPNS_KEY_NAME,
      content: JSON.stringify(allIpfsPaths),
    });
    await axios.post('/ipns/publish', {
      keyName: IPNS_KEY_NAME,
      ipfsPath: data.ipfsPath,
    });
    window.location.reload();
    console.log(`{ipfsPath: ${data.ipfsPath}}`);
  } catch (e) {
    console.log('Failed to publish IPNS record: ', e);
    window.location.reload();
    return genericErrorReturn();
  }
}

async function pushClientsToDB(missedClients, userObj) {
  const pushedFilePaths = [];
  for (const u of missedClients) {
    // TODO async
    const uid = genUid();
    const name = u;
    const rsaKey = new NodeRSA();
    rsaKey.importKey(userObj[u], 'private');
    const pk = rsaKey.exportKey('public');
    const fileContent = JSON.stringify({
      type: TRADER_TYPE,
      id: uid,
      name,
      pk,
      dismissedBids: [],
    });
    try {
      const { data } = await axios.post('/ipfs', {
        name: uid,
        content: fileContent,
      });
      pushedFilePaths.push(data.ipfsPath);
    } catch (e) {
      console.log('Failed to insert Trader document: ', e);
      return genericErrorReturn();
    }
  }
  return pushedFilePaths;
}

Initializer.propTypes = {
  tradersLoaded: PropTypes.bool.isRequired,
  setIpfsDirHash: PropTypes.func.isRequired,
  setIpfsFiles: PropTypes.func.isRequired,
  parseFilesContent: PropTypes.func.isRequired,
  setNewCidToNames: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  tradersLoaded: store.db.traderIds.length > 0,
});

const mapDispatchToProps = dispatch => ({
  setNewCidToNames: cidToNames => {
    dispatch(setNewCidToNames(cidToNames));
  },
  setIpfsDirHash: hash => {
    dispatch(setIpfsDirHash(hash));
  },
  setIpfsFiles: (filesIds, newFiles) => {
    dispatch(setIpfsFiles(filesIds, newFiles));
  },
  parseFilesContent: files => {
    dispatch(parseFilesContent(files));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Initializer);
