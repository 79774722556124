import { Box, Text } from 'grommet/es6';
import React, { Component } from 'react';
import styles from './Users.module.scss';
import { USERS, COUNTRY } from './../../util/constants';
import axios from 'axios';
// import countryState from './countryState'
import PropTypes from 'prop-types';
import { showErrorToast, showSuccessToast } from '../../util/toast-service';

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      userForm: {
        gender: 'male',
        tradeType: 'British Petroleum',
        licenseAttachment: '',
        status: 'Active',
        role: 'User',
        country: 'India',
        isExistUser: false,
      },
      isErrorMsg: false,
      errorMsg: '',
    };
  }

  async componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  handleChange = evt => {
    this.setState({ userForm: { ...this.state.userForm, [evt.target.name]: evt.target.value } });
    if (evt?.target?.name === 'email') {
      this.checkExistingMail(evt?.target?.value);
    }
  };

  createNewUser = () => {
    var { email, password, role, tradeType, isExistUser } = this.state?.userForm;
    console.log('this.state :>> ', this.state);
    if (tradeType === undefined) {
      tradeType = 'British Petroleum';
    }
    const status = 'Active';
    if (!password || !role || !status) {
      showErrorToast('Please fill all fields.');
      return;
    }

    const userPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!userPattern.test(email)) {
      showErrorToast('Invalid email address');
      return false;
    }

    if (isExistUser) {
      showErrorToast('User already exists');
      return;
    }

    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    if (!passPattern.test(password)) {
      showErrorToast(
        '<p><b>Invalid Password. </b></p> <p>Password should have one Uppercase, one number, one special character.</p> <p>It should have minimum 8 characters, maximum upto 20 characters. </p>',
        8000,
      );
      return;
    }

    const userObj = {
      ...this.state.userForm,
      fullName: this.state?.userForm?.firstName + ' ' + this.state?.userForm?.lastName,
    };
    userObj.email = userObj.email.toLowerCase();

    axios
      .post('/user', userObj)
      .then(res => {
        console.log('res :>> ', res);
        this.props.history.push('/administration/userlist');
        showSuccessToast('User created successfully.');
      })
      .catch(err => {
        console.log('err :>> ', err);
        showErrorToast('Error occured.');
      });
  };

  toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  detectMimeType = b64 => {
    var signatures = {
      JVBERi0: 'application/pdf',
      R0lGODdh: 'image/gif',
      R0lGODlh: 'image/gif',
      iVBORw0KGgo: 'image/png',
      '/9j/': 'image/jpg',
    };

    for (var s in signatures) {
      if (b64.substring(0, 50).includes(s)) {
        return signatures[s];
      }
    }
    return null;
  };

  handleSelect = ev => {
    this.setState({ userForm: { ...this.state.userForm, [ev.target.name]: ev.target.value } });
  };

  onFileChange = async event => {
    try {
      console.log('event.target.files :>> ', event.target.files[0]);
      const base64Code = await this.toBase64(event.target.files[0]);
      const mimeType = this.detectMimeType(base64Code);

      if (!mimeType) {
        showErrorToast('Invalid file.');
        return;
      }

      this.setState({ userForm: { ...this.state.userForm, licenseAttachment: base64Code } });
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  checkExistingMail(email) {
    axios.get(`/user/${email.toLowerCase()}`, { validateStatus: () => true }).then(res => {
      if (res?.status === 200) {
        this.setState({ userForm: { ...this.state.userForm, isExistUser: true } });
        showErrorToast('User already exists');
        return;
      }
      this.setState({ userForm: { ...this.state.userForm, isExistUser: false } });
    });
  }

  render() {
    var { isLoaded, userForm } = this.state;

    if (!isLoaded) {
      return <div>Loading.....</div>;
    } else {
      return (
        <Box className={styles.Box1}>
          <h2 className={styles.h2}>New User</h2>
          <div className={(styles.rows, 'row')}>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>FirstName</Text>
              <input
                className={styles.inputbox2}
                type="text"
                placeholder="Enter Your firstname"
                name="firstName"
                onChange={this.handleChange}
              />
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>LastName</Text>
              <input
                className={styles.inputbox2}
                type="text"
                placeholder="Enter Your lastname"
                name="lastName"
                onChange={this.handleChange}
              />
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text3}>Gender</Text>
              <select
                className={styles.inputbox3}
                placeholder="Choose a gender"
                onChange={this.handleSelect}
                name="gender"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Email ID</Text>
              <input
                className={styles.inputbox2}
                type="text"
                placeholder="Enter Your email id"
                name="email"
                onChange={this.handleChange}
              />
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text3}>Choose trade partner</Text>
              <select
                className={styles.inputbox3}
                name="tradeType"
                placeholder="Choose a trade partner"
                onChange={this.handleChange}
                disabled={userForm?.role === 'Admin'}
              >
                {USERS.map(x => (
                  <option key={x.name} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text3}>Role</Text>
              <select
                className={styles.inputbox3}
                placeholder="Choose a role"
                onChange={this.handleSelect}
                name="role"
              >
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Password</Text>
              <input
                className={styles.inputbox2}
                type="password"
                placeholder="Enter Your password"
                name="password"
                onChange={this.handleChange}
              />
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Street</Text>
              <input
                className={styles.inputbox2}
                type="text"
                placeholder="Enter Your street"
                name="street"
                onChange={this.handleChange}
              />
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>City</Text>
              <input
                className={styles.inputbox2}
                type="text"
                name="city"
                placeholder="Enter Your City"
                onChange={this.handleChange}
              />
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>State</Text>
              <input
                className={styles.inputbox2}
                type="text"
                name="state"
                placeholder="Enter Your State"
                onChange={this.handleChange}
              />
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Zip Code</Text>
              <input
                className={styles.inputbox2}
                type="text"
                name="zipcode"
                placeholder="Enter Your Zip code"
                onChange={this.handleChange}
              />
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Country</Text>
              <select
                className={styles.inputbox2}
                placeholder="Choose country"
                onChange={this.handleSelect}
                name="country"
                value={userForm?.country}
              >
                {COUNTRY.map(x => (
                  <option key={x.name} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Nationality</Text>
              <input
                className={styles.inputbox2}
                type="text"
                name="nationality"
                placeholder="Enter Your Nationality"
                onChange={this.handleChange}
              />
            </div>

            <div className={(styles.mains, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>License ID</Text>
              <input
                className={styles.inputbox2}
                type="text"
                name="licenseID"
                placeholder="Enter Your License ID"
                onChange={this.handleChange}
              />
            </div>

            <div className={(styles.main1, 'col-xs-12 col-md-12 col-sm-12 col-lg-3 col-xl-3 d-flex flex-column')}>
              <Text className={styles.Text1}>Choose File</Text>
              <div className={styles.buttonNew}>
                <button>Browse</button>
                <input onChange={this.onFileChange} type="file" />
              </div>
              {!userForm?.licenseAttachment ? (
                <p className="text-dark">
                  <b>Supported Formats: </b>jpg, png, pdf
                </p>
              ) : null}
              {userForm?.licenseAttachment ? <p className="text-dark">File selected</p> : null}
            </div>
          </div>

          <div className={(styles.main, 'd-flex')}>
            <button onClick={() => this.createNewUser()} className={styles.submit}>
              Submit
            </button>
          </div>
        </Box>
      );
    }
  }
}
NewUser.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default NewUser;
