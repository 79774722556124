import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { deliveryTermsOptions } from '../../../util/formFieldsUtils';

const DeliveryTerms = ({ deliveryTerms, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box align="center" direction="row" justify="evenly">
            <Box width="100%" alignSelf="start">
              <FormField label="Delivery Terms">
                <Select
                  plain
                  name="deliveryTerms"
                  placeholder="Select a Delivery Terms"
                  value={deliveryTerms}
                  options={deliveryTermsOptions.map(qo => (
                    <span key={qo.val}>{qo.lab}</span>
                  ))}
                  onChange={({ option }) => handleSelect('deliveryTerms', option)}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

DeliveryTerms.propTypes = {
  deliveryTerms: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default DeliveryTerms;
