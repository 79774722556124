// import React from 'react';
import { Box, Text } from 'grommet/es6';
import React, { useState } from 'react';
import styles from './Login.module.scss';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { setLoggedUser } from '../../actions/logggedUserActions';
import PropTypes from 'prop-types';
import { setUser } from '../../actions/userActions';
import { parseFilesContent } from '../../actions/dbActions';
import { USERS } from '../../util/constants';
import getUserDetails from '../../util/getUserDetails';
import { loadAllFilePaths } from '../../util/cargoUtil';
import { showErrorToast, showSuccessToast } from '../../util/toast-service';
// import Captcha from '../Captcha/Captcha';

const Login = ({ setUser, setLoggedUser, parseFilesContent, ipfsFilesContent, ipfsFilesIds }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpResendEnable, setOtpResendEnable] = useState(false);
  const [loginStep, setLoginStep] = useState(1);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});

  const history = useHistory();

  async function storeLoggedUserData(loggedUserData) {
    const userDetails = await axios.get(`/user/profile`);
    setLoggedUser(
      loggedUserData.token,
      loggedUserData.user,
      loggedUserData.session,
      userDetails?.data,
    );

    const userObj = {
      token: loggedUserData.token,
      user: loggedUserData.user,
      session: loggedUserData.session,
      otherDetails: userDetails?.data,
    };

    localStorage.setItem('loggedUser', JSON.stringify(userObj));
    var selectedTradeType = 'British Petroleum';
    if (userDetails?.data?.tradeType) {
      selectedTradeType = userDetails?.data?.tradeType;
    }
    const selectedTrade = USERS.findIndex(x => x.name === selectedTradeType);
    onSelectTrade(selectedTrade);
  }

  const onSelectTrade = hardCodedIdx => {
    // get user details from hard-coded index
    console.log(hardCodedIdx);
    console.log('_____________________');
    const { rsaKey, dismissedBids, id } = getUserDetails(hardCodedIdx);
    // dispatch action to set user
    setUser(rsaKey, dismissedBids, id, hardCodedIdx);
    // dispatch action to parse DB files
    const filesContent = ipfsFilesIds.map(id => ipfsFilesContent[id]);
    parseFilesContent(filesContent, rsaKey);
    history.push('/');
    loadAllFilePaths();
  };

  async function login() {
    if (!email || !password) {
      showErrorToast('Invalid email/password.');
      return;
    }

    // const userPattern = /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){6,18}[a-zA-Z0-9]$/;
    const userPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!userPattern.test(email)) {
      // showErrorToast(
      //   "<p><b>Invalid Username.</b></p><p>Username only contains alphanumeric characters, underscore and dot.</p> <p>Underscore and dot can't be at the end or start of a username.</p><p>Underscore and dot can't be next to each other.</p><p>Underscore or dot can't be used multiple times in a row.</p><p>Number of characters must be between 8 to 20.</p>",
      //   8000,
      // );
      showErrorToast('Invalid email address');
      return;
    }

    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%&*()!?])[A-Za-z\d@#$%&*()!?]{8,20}$/;
    if (!passPattern.test(password)) {
      showErrorToast(
        '<p><b>Invalid Password. </b></p> <p>Password should have one Uppercase, one number, one special character.</p> <p>It should have minimum 8 characters, maximum upto 20 characters. </p>',
        8000,
      );
      return;
    }
    setIsError(false);
    setErrorMsg('');

    const loginData = { email: email.toLowerCase(), password };
    setOtpResendEnable(false);

    axios.post('/auth/login', loginData, { validateStatus: () => true }).then(res => {
      if (res.status === 200) {
        showSuccessToast(res?.data?.error || 'OTP sent to your email.');
        setLoginStep(2);
        setTimeout(() => {
          setOtpResendEnable(true);
        }, 5000);
        return;
      }
      showErrorToast(res?.data?.error || 'Cannot submitted a request.');
      console.log('Error occured: ', res);
    });

    // try {
    //   const { data } = await axios.post('/auth/login', loginData);
    //   showSuccessToast(data?.error || 'OTP sent to your email.');
    //   setLoginStep(2);

    //   setTimeout(() => {
    //     setOtpResendEnable(true);
    //   }, 5000);
    // } catch (e) {
    //   showErrorToast('Wrong username/password.');
    //   console.log('Error occured: ', e);
    // }
  }

  async function verifyReceivedOtp() {
    if (!otp || otp?.length < 6) {
      showErrorToast('Invalid OTP.');
      return;
    }

    const otpData = { email: email.toLowerCase(), otp };
    try {
      const { data } = await axios.post('/auth/verify', otpData);
      if (!data?.token) {
        return;
      }
      sessionStorage.setItem('token', data?.token);
      storeLoggedUserData(data);
      showSuccessToast('Logged in successfully');
    } catch (e) {
      showErrorToast('Wrong OTP.');
      console.log('Error occured: ', e);
    }
  }

  async function signup() {
    history.push('/signup');
  }
  async function ForgetPassword() {
    history.push('/forgetpassword');
  }
  return (
    <div className={(styles.mainLayout)}>
        <Box className={(styles.welcomeBox)}>
        <h1>Welcome to Arbopen</h1>
        <h3 className={styles.subtitle}>Select a demo account</h3>
      </Box>
      <Box className={(styles.Box)}>
        {isError ? (
          <div className={styles.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
        ) : null}

        {loginStep === 1 ? (
          <form onSubmit={e => e.preventDefault()} className={styles.formWrapper}>
            <h3 className={styles.Text1}>Log in</h3>
            <div className={styles.main}>
              <Text className={styles.Text}>Email</Text>
            </div>
            <input
              className={styles.inputbox1}
              type="text"
              placeholder="Enter Your Email"
              onChange={e => setEmail(e.target.value)}
            />
            <div className={styles.main}>
              <Text className={styles.Text}>Password</Text>
            </div>
            <input
              className={styles.inputbox1}
              type="password"
              placeholder="Enter Your Password"
              onChange={e => setPassword(e.target.value)}
            />
            
            <Box className={styles.signup_box}>
              <button type="submit" onClick={login} className={styles.submit}>
                Log in
              </button>
              <button type="submit" onClick={signup} className={styles.signup}>
                Signup
              </button>
            </Box>
            <Box className={styles.signup_box}>
              <a className={styles.forgetpassword} onClick={ForgetPassword}>
                Forget Password
              </a>
            </Box>
          </form>
        ) : null}

        {loginStep !== 1 ? (
          <form onSubmit={e => e.preventDefault()} className={styles.formWrapper}>
            <h3 className={styles.Text1}>Verify OTP</h3>
            <div className={styles.main}>
              <Text className={styles.Text}>OTP</Text>
            </div>
            <input
              className={styles.inputbox1}
              type="text"
              placeholder="Enter Your Otp"
              onChange={e => setOtp(e.target.value)}
            />

            <Box className={styles.signup_box}>
              <button type="submit" onClick={verifyReceivedOtp} className={styles.submit}>
                Verify
              </button>
              <button
                type="submit"
                disabled={!otpResendEnable}
                onClick={login}
                className={styles.signup}
              >
                Resend OTP
              </button>
            </Box>
          </form>
        ) : null}
        
        
      </Box>
    </div>
  );
};
Login.propTypes = {
  ipfsFilesContent: PropTypes.objectOf(PropTypes.string),
  ipfsFilesIds: PropTypes.arrayOf(PropTypes.string),
  setUser: PropTypes.func.isRequired,
  setLoggedUser: PropTypes.func.isRequired,
  parseFilesContent: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => ({
  setUser: (rsaKey, dismissedBids, id, hardCodedIdx) => {
    dispatch(setUser(rsaKey, dismissedBids, id, hardCodedIdx));
  },
  setLoggedUser: (token, user, session, otherDetails) => {
    dispatch(setLoggedUser(token, user, session, otherDetails));
  },
  parseFilesContent: (files, rsaPrivateKey) => {
    dispatch(parseFilesContent(files, rsaPrivateKey));
  },
});
const mapStateToProps = store => ({
  ipfsFilesContent: store.db.ipfsFilesContent,
  ipfsFilesIds: store.db.ipfsFilesIds,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
