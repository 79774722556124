import Web3 from 'web3';
import { CERTIFICATION_ABI } from './constants';

const {
  REACT_APP_ETH_PROVIDER,
  REACT_APP_WALLET,
  REACT_APP_WALLET_PWD,
  REACT_APP_CERTIFICATION_ADDRESS,
} = process.env;

const TTProvider = new Web3.providers.HttpProvider(REACT_APP_ETH_PROVIDER);
const web3 = new Web3(TTProvider);

const json = "{\"version\":3,\"id\":\"21f26eff-6f36-4656-bef7-cfd4b20c0d15\",\"address\":\"93952b0d8913b92405cd31e983bf0862e7e75626\",\"crypto\":{\"ciphertext\":\"1945db80a6799ee1d0f06193be489aab633801c995923f7ffed90d7dd8eea9a2\",\"cipherparams\":{\"iv\":\"6798a3bc72cf5d582d844057b8854686\"},\"cipher\":\"aes-128-ctr\",\"kdf\":\"scrypt\",\"kdfparams\":{\"dklen\":32,\"salt\":\"6e2bddac0e2dda086fee608eab05334e640b84b6700aa745975bd1431cd78212\",\"n\":131072,\"r\":8,\"p\":1},\"mac\":\"11c1c787b33d50518ed1ac0a37b34d68cfb4a5ecbbb2eecf8f295a3ddb4ec56b\"}}"
const wallet = web3.eth.accounts.wallet.decrypt(
     [JSON.parse(json)],
  'xM}bD"o6:psTN_Ra6*=r5}5',
);
web3.eth.Contract.defaultAccount = wallet[0].address;

const certificationContract = new web3.eth.Contract(
  CERTIFICATION_ABI,
  REACT_APP_CERTIFICATION_ADDRESS,
);

async function certify(hash) {
  await certificationContract.methods
    .certify(web3.utils.soliditySha3(hash))
    .send({ from: wallet[0].address, chainId: 108, gas: 60000 });
}

export { certificationContract, certify };
