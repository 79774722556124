import sr from 'seed-random';
import hasha from 'hasha';
import bs58 from 'bs58';
import { MONTHS } from './constants';
import { clearLoggedUser } from '../actions/logggedUserActions';

/**
 * Generate a random buffer of given length.
 * @param length Number The length in bytes wished for the buffer.
 * @returns {Buffer} Random buffer of given length.
 */
const genRdmBuffer = length => {
  length = Math.max(1, length << 0);
  const rand = sr();
  const buf = Buffer.alloc(length);
  for (let i = 0; i < length; ++i) buf[i] = (rand() * 0xff) << 0;
  return buf;
};

/**
 * Generate a new UID that can be used safely to store a new document in DB.
 * @returns {string} UID.
 */
const genUid = () => genRdmBuffer(16).toString('hex');

/**
 * Generate a deterministic Cargo name from its UID.
 * @param uid String Uid of the Cargo document.
 * @returns {string} Six-character base58 name.
 */
const genCargoName = uid =>
  bs58.encode(hasha(uid, { algorithm: 'sha256', encoding: 'buffer' })).substr(0, 6);

/**
 * Converts a UNIX timestamps range to a custom string format.
 * @param timestamps: UNIX timestamps range.
 * @returns {string} custom string
 */
const rangeToString = timestamps => {
  const date1 = new Date(timestamps[0] * 1000);
  const date2 = new Date(timestamps[1] * 1000);
  const month1 = date1.getMonth();
  const month2 = date2.getMonth();
  if (month1 !== month2) {
    return `${date1.getDate()} ${MONTHS[month1]} - ${date2.getDate()} ${MONTHS[month2]}`;
  } else {
    return `${date1.getDate()} - ${date2.getDate()} ${MONTHS[month1]}`;
  }
};

const getToken = () => sessionStorage.getItem('token');

const logout = () => {
  sessionStorage.removeItem('token');
  localStorage.removeItem('loggedUser');
  clearLoggedUser();
};

/**
 * Comparison function for filters
 * @param object: Object to compare to filter.
 * @param object: Filter to compare to the object.
 * @returns {bool} True if object passes filter, false otherwise
 */
const deepCompare = (object, filter) => {
  const filterKeys = Object.keys(filter);

  return filterKeys.every(fk => {
    switch (typeof filter[fk]) {
      case 'string':
        return !filter[fk] || (filter[fk] && object[fk].includes(filter[fk]));
      case 'object':
        if (Array.isArray(filter[fk])) {
          return !(
            (filter[fk][0] && parseInt(object[fk]) < parseInt(filter[fk][0])) ||
            (filter[fk][1] && parseInt(object[fk]) > parseInt(filter[fk][1]))
          );
        }
    }
  });
};

const getPaginationArray = (totalPages, currentPage) => {
  const pageNumbers = [];
  if (totalPages <= 10) {
    for (var i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    for (
      var j = currentPage < 5 ? 1 : currentPage - 4;
      j < (currentPage + 5 < totalPages ? currentPage + 5 : totalPages + 1);
      j++
    ) {
      pageNumbers.push(j);
    }
  }

  return pageNumbers;
};

export {
  genRdmBuffer,
  genUid,
  genCargoName,
  rangeToString,
  deepCompare,
  getToken,
  logout,
  getPaginationArray,
};
