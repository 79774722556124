import { Box, Table, Button, Text } from 'grommet/es6';
import styles from './Auditlog.module.scss';
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router';
import LoadingRange from './../NewCAP/FormFields/LoadingRange';
import axios from 'axios';
import util from 'util/util';
import { getPaginationArray } from '../../util/util';
import $ from 'jquery';
import { ALL_ACTIONS } from '../../util/constants';

const initialState = () => ({
  showStartCalendar: false,
  showEndCalendar: false,
  loadingRangeStart: null,
  loadingRangeEnd: null,
  isFilterNeeded: false,
  isLoading: false,
  userLogs: [],
  users: [],
  searchValue: '',
  tradeType: '',
  listSearchUser: [],
  currentPage: 1,
  selectUser: '',
  startDate: '',
  endDate: '',
  size: 10,
  totalPages: 1,
  util,
});
class Auditlog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.userElRef = React.createRef();
  }

  async getAuditLogs() {
    this.setState({ isLoading: true });

    const { currentPage, size, selectUser, startDate, tradeType, endDate } = await this.state;

    await this.setState({ userLogs: [] });
    axios
      .get(
        `/auditlog?page=${currentPage}&size=${size}&user=${selectUser}&trade=${tradeType}&start=${startDate}&end=${endDate}`,
      )
      .then(res => {
        const responseObj = res?.data;
        this.setState({ isLoading: false });
        if (responseObj?.data) {
          this.setState({
            userLogs: responseObj.data.filter(x => x.actionOn != null),
            isLoading: false,
            totalPages: responseObj?.page?.totalPages,
          });
        }
      })
      .catch(err => {
        console.log('err :>> ', err);
        this.setState({ isLoading: false });
      });
  }

  async onUserChange(ev) {
    var selectValue = ev.target.value;
    await this.setState({ selectUser: selectValue });
    this.getUsers();
    this.getAuditLogs();
  }

  async startDate(ev) {
    var value = new Date(ev.target.value);
    if (value != null) {
      var selectValue = value / 1000;
      console.log(selectValue);
      await this.setState({ startDate: selectValue });
      const endDate = await this.state.endDate;
      if (!endDate || endDate === '') {
        return;
      }
      this.getAuditLogs();
    } else {
      this.setState({ startDate: '' });
    }
  }

  async endDate(ev) {
    var value = new Date(ev.target.value);
    if (value != null) {
      var selectValue = value / 1000;
      await this.setState({ endDate: selectValue });
      const startDate = await this.state.startDate;
      if (!startDate || startDate === '') {
        return;
      }
      this.getAuditLogs();
    } else {
      this.setState({ endDate: '' });
    }
  }

  async getUsers() {
    let userInput = '';

    const { searchValue } = await this.state;

    if (searchValue && searchValue !== '') {
      userInput = searchValue;
    }

    this.setState({
      users: [],
    });
    axios
      .get(`/user?page=&size=&search=${userInput}`)
      .then(res => {
        const responseObj = res?.data;
        console.log('res', responseObj);
        if (responseObj?.data) {
          this.setState({
            users: responseObj.data,
            isLoading: false,
          });

          const users = responseObj.data;
          if (users.length > 0) {
            const searchList = users.map(x => {
              const y = {
                value: x.email,
                label: x.fullName,
                className: 'userFilter',
              };
              return y;
            });

            this.setState({ listSearchUser: searchList });
          }
        }
      })
      .catch(err => {
        console.log('err :>> ', err);
        this.setState({ isLoading: false });
      });
  }

  toggleShowCalendar = (isStart, date) => {
    const { showStartCalendar, showEndCalendar } = this.state;
    if (isStart) {
      this.setState({
        showStartCalendar: !showStartCalendar,
        loadingRangeStart: date,
      });
    } else {
      this.setState({
        showEndCalendar: !showEndCalendar,
        loadingRangeEnd: date,
      });
    }
  };

  formatDateTime(dateNum) {
    const dateObj = new Date(dateNum * 1000);
    return `${dateObj.getDate()} -
        ${dateObj.getMonth() + 1} - 
        ${dateObj.getFullYear()} - 
        ${dateObj.toLocaleTimeString()}`;
  }

  componentDidMount() {
    this.getAuditLogs();
    this.getUsers();
  }

  async switchPage(page) {
    await this.setState({ currentPage: page });
    this.getAuditLogs();
  }

  async clearFilter() {
    console.log('clear');
    await this.setState({ selectUser: '', startDate: '', endDate: '', users: [], tradeType: '' });
    $('#startDate').val('');
    $('#endDate').val('');
    // this.userElRef.current.selectValue('');
    // $('#user')
    //   .find('option')
    //   .remove()
    //   .end()
    //   .val('');
    this.getAuditLogs();
    this.getUsers();
  }

  async handleUserSearch(ev) {
    this.setState({ searchValue: ev });
    this.getUsers();
  }

  async tradeTypeChange(ev) {
    const tradeType = ev?.target?.value;
    await this.setState({ tradeType: tradeType || '' });
    this.getAuditLogs();
  }

  render() {
    const {
      showStartCalendar,
      showEndCalendar,
      loadingRangeStart,
      loadingRangeEnd,
      userLogs,
      isLoading,
      totalPages,
      currentPage,
      tradeType,
      size,
    } = this.state;

    return (
      <Box className={styles.BoxAuditLog}>
        <Box>
          <div className="container">
            <div className="row">
              <div className={'col-xs-12 col-sm-12 col-md-2 col-lg-3 col-xl-3'}>
                {/* <input
                  type="text"
                  className={styles.inputboxaudit}
                  name="tradeType"
                  placeholder="Action"
                  id="tradeType"
                  onChange={this.tradeTypeChange.bind(this)}
                  value={tradeType}
                /> */}
                <select
                  className={styles.inputboxaudit}
                  name="tradeType"
                  placeholder="Action"
                  id="tradeType"
                  onChange={this.tradeTypeChange.bind(this)}
                  value={tradeType}
                >
                  <option value="">All</option>
                  {ALL_ACTIONS.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={'col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-3'}>
                <input
                  className={styles.inputboxaudit}
                  type="date"
                  name="startDate"
                  placeholder="Start date"
                  id="startDate"
                  onChange={this.startDate.bind(this)}
                />
              </div>
              <div className={'col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'}>
                <input
                  className={styles.inputboxaudit}
                  type="date"
                  placeholder="End date"
                  name="EndDate"
                  id="endDate"
                  onChange={this.endDate.bind(this)}
                />
              </div>
              <div className={'col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'}>
                {/* <Select
                  className={styles.basic}
                  classNamePrefix="user-filter"
                  isClearable="true"
                  isSearchable="true"
                  onChange={async ev => {
                    console.log('ev :>> ', ev);
                    await this.setState({ selectUser: ev?.value || '' });
                    this.getAuditLogs();
                  }}
                  ref={this.userElRef}
                  onInputChange={e => {
                    this.handleUserSearch(e);
                  }}
                  name="user"
                  options={listSearchUser}
                /> */}
                <input
                  className={styles.inputboxaudit}
                  classNamePrefix="user-filter"
                  isClearable="true"
                  placeholder="User"
                  isSearchable="true"
                  onChange={async ev => {
                    console.log('ev :>> ', ev?.target?.value);
                    await this.setState({ selectUser: ev?.target?.value || '' });
                    this.getAuditLogs();
                  }}
                  name="user"
                />
              </div>
              <div className={'col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'}>
                <button
                  className={styles.inputboxaudit}
                  type="button"
                  onClick={() => this.clearFilter()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </Box>
        {this.state.isFilterNeeded ? (
          <div className={styles.datebox}>
            <LoadingRange
              formLabel={' '}
              loadingRangeStart={loadingRangeStart}
              loadingRangeEnd={loadingRangeEnd}
              showStartCalendar={showStartCalendar}
              showEndCalendar={showEndCalendar}
              toggleShowCalendar={this.toggleShowCalendar}
            />

            <Button className={styles.button}>submit</Button>
          </div>
        ) : null}
        <Table className={(styles.table_box, 'table-responsive')}>
          <Table className={(styles.table_box, 'table table-bordered')}>
            <thead className={styles.table_head}>
              <tr>
                <th>S.No</th>
                <th className={styles.head1}>Email</th>
                <th className={styles.head2}>Action </th>
                <th className={styles.head2}>Comments </th>
                <th className={styles.head2}>Time</th>
              </tr>
            </thead>
            <tbody>
              {userLogs.map((x, i) => (
                <tr key={i}>
                  <td>{(currentPage - 1) * size + i + 1}</td>
                  <td>{x.email}</td>
                  <td>{x.action || ''}</td>
                  <td className={styles.auditComment}>{x.comment || '---'}</td>
                  <td>{x.actionOn?.T ? this.formatDateTime(x.actionOn?.T) : ''} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Table>
        {isLoading ? (
          <Box className={styles.isLoading}>
            <p>Loading</p>
          </Box>
        ) : null}
        {!isLoading && userLogs.length === 0 ? (
          <Box className={styles.isLoading}>
            <Text>No logs.</Text>
          </Box>
        ) : null}
        <footer className={'py-1'}>
          {totalPages > 1 ? (
            <div className={styles.pagination}>
              {totalPages > 3 ? <a onClick={() => this.switchPage(1)}>&laquo;</a> : null}

              {getPaginationArray(totalPages, currentPage).map((tile, index) => (
                <a
                  key={index}
                  onClick={() => this.switchPage(tile)}
                  className={currentPage === tile ? 'active' : ''}
                >
                  {' '}
                  {tile}
                </a>
              ))}

              {totalPages > 3 ? <a onClick={() => this.switchPage(totalPages)}>&raquo;</a> : null}
            </div>
          ) : null}
        </footer>
      </Box>
    );
  }
}

// export default Auditlog;
export default withRouter(Auditlog);
