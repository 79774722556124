import React, { useState } from 'react';
import { Box, Select, ResponsiveContext } from 'grommet';
import styles from '../Trading.module.scss';
import { TROLL, FORTIES, OSEBERG } from '../../../util/constants';
import { graph0, graph1, graph2 } from '../../../media/graphs';

const Graphs = () => {
  const options = [TROLL, FORTIES, OSEBERG];
  const [value, setValue] = useState(TROLL);

  function getSrc() {
    switch (value) {
      case TROLL:
        return graph0;
      case FORTIES:
        return graph1;
      default:
        return graph2;
    }
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box gridArea="graph" className={styles['graph-wrapper']}>
          <Box
            className={`${styles['graph-select']} ${
              size !== 'large' ? styles['small-screen'] : styles['other-screen']
            }`}
          >
            <Select
              id="select"
              name="select"
              value={value}
              options={options}
              onChange={({ option }) => setValue(option)}
            />
          </Box>
          <img
            className={styles['graph-img']}
            src={getSrc()}
            alt="Graph1"
            style={{ height: size !== 'large' ? '160px' : '300px' }}
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Graphs;
