import React, { useState } from 'react';
import TableArea from '../TableArea';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rangeToString } from '../../../util/util';

const Products = ({
  docs,
  saleIdsAsBuyer,
  tradePropsAsSellerIds,
  setCurrentBid,
  setCurrentAsk,
  setCurrentSale,
  selected,
}) => {
  const [search, setSearch] = useState('');

  const products = [];

  for (const saleId of saleIdsAsBuyer) {
    try {
      const saleUnixTimestamp = docs[saleId].timestamp;
      const cargoId = docs[docs[saleId].tradeProposition].cargo;

      if (
        tradePropsAsSellerIds
          .filter(tpId => docs[tpId].cargo === cargoId)
          .every(tpId => docs[tpId].timestamp.getTime() < saleUnixTimestamp)
      ) {
        const cargo = docs[cargoId];

        products.push({
          id: cargo.id,
          name: cargo.name,
          grade: cargo.grade,
          cargoSize: cargo.cargoSize,
          quality: cargo.quality,
          txTime: saleUnixTimestamp,
          cargoLoadingRange: [cargo.loadingRangeStart, cargo.loadingRangeEnd],
          lastPrice: docs[saleId].price,
          loadingRange: rangeToString([
            (new Date(cargo.loadingRangeStart).getTime() / 1000).toFixed(0),
            (new Date(cargo.loadingRangeEnd).getTime() / 1000).toFixed(0),
          ]),
        });
      }
    } catch (err) {
      console.log('err :>> ', err);
    }
  }
  products.sort((a, b) => b.txTime - a.txTime);

  const columns = [
    {
      name: 'name',
      label: 'Tracking Number',
      weight: 'bold',
      size: '12px',
    },
    {
      name: 'cargoSize',
      label: 'Quantity',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'grade',
      label: 'Grade',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'loadingRange',
      label: 'Loading range',
      weight: 'normal',
      size: '11px',
    },
  ];

  return (
    <TableArea
      title="My products"
      columns={columns}
      data={products}
      type="products"
      setCurrentBid={setCurrentBid}
      setCurrentAsk={setCurrentAsk}
      setCurrentSale={setCurrentSale}
      selected={selected}
      search={search}
      setSearch={setSearch}
    />
  );
};

Products.propTypes = {
  setCurrentBid: PropTypes.func.isRequired,
  setCurrentAsk: PropTypes.func.isRequired,
  setCurrentSale: PropTypes.func.isRequired,
  selected: PropTypes.object,
  docs: PropTypes.objectOf(PropTypes.object),
  saleIdsAsBuyer: PropTypes.arrayOf(PropTypes.string),
  tradePropsAsSellerIds: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = store => {
  return {
    docs: store.db.docs,
    saleIdsAsBuyer: store.db.saleIds.filter(
      saleId => store.db.docs[saleId].buyer === store.user.id,
    ),
    tradePropsAsSellerIds: store.db.tradePropsAsSellerIds,
  };
};

export default connect(mapStateToProps)(Products);
