import axios from 'axios';
import { showToast } from './toast-service';
import { logout } from './util';
const initAxios = () => {
  axios.interceptors.request.use(config => {
    // config.url = (process.env.REACT_APP_IPNS_API_BASE_URL || 'http://127.0.0.1:8800') + config.url;
    config.url =
      (process.env.REACT_APP_IPNS_API_BASE_URL || 'https://api.arbopenexchange.com') + config.url;
    if (!config.auth) {
      config.headers = { Token: `Bearer ${sessionStorage.getItem('token')}` };
    }
    return config;
  });
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response.status === 401 &&
        error?.response?.config?.url.indexOf('/auth/login') === -1
      ) {
        const existLoggedUserData = localStorage.getItem('loggedUser');
        if (!existLoggedUserData) {
          logout();
          return;
        }
        const userData = JSON.parse(existLoggedUserData);
        const logoutObj = { session: userData.session, user: userData.user };
        axios
          .post('/auth/logout', logoutObj)
          .then(res => {
            localStorage.removeItem('loggedUser');
            sessionStorage.removeItem('token');
            logout();
            showToast('Session expired.');
            window.location.reload('/');
          })
          .catch(err => {
            console.log('err :>> ', err);
          });
        logout();
      }
    },
  );
};

const sendAuditLogEntry = action => {
  const statuObj = { action };
  axios
    .post('/auditlog/entry', statuObj)
    .then(res => {
      console.log('saved log: >>', Date.now());
    })
    .catch(err => {
      console.log('err :>> ', err);
    });
};

export { initAxios, sendAuditLogEntry };
