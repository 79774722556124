import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { OperationalToleranceOptions, optionsInCargo } from '../../../util/formFieldsUtils';

const OperationTolerance = ({ operationalTolerance, options, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box direction="row" justify="evenly">
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'} alignSelf="start">
                <FormField label="Operational Tolerance (%)">
                  <Select
                    plain
                    name="operationalTolerance"
                    placeholder="Select a operation tolerance"
                    value={operationalTolerance}
                    options={OperationalToleranceOptions.map(qo => (
                      <span key={qo.val}>{qo.lab}</span>
                    ))}
                    onChange={({ option }) => handleSelect('operationTolerance', option)}
                  />
                </FormField>
              </Box>
            </Box>
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'}>
                <FormField label="Options">
                  <Select
                    plain
                    name="options"
                    placeholder="Select a options"
                    value={options}
                    options={optionsInCargo.map(qo => (
                      <span key={qo.val}>{qo.lab}</span>
                    ))}
                    onChange={({ option }) => handleSelect('options', option)}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

OperationTolerance.propTypes = {
  operationalTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default OperationTolerance;
