import { genRdmBuffer } from './util';

/**
 * Asymmetrically encrypt a UID with RSA.
 * @param uid 32-hex-char String
 * @param key NodeRSA key object with public key.
 * @returns {String} Base64 cipher.
 */
const rsaEncryptUid = (uid, key) => {
  const salt = genRdmBuffer(32);
  const text = uid + salt.toString('hex');
  return key.encrypt(text, 'base64', 'hex');
};

/**
 * Asymmetrically decrypt a UID with RSA.
 * @param cipher String Base64 cipher.
 * @param key NodeRSA key object with private key.
 * @returns {string} Clear UID.
 */
const rsaDecryptUid = (cipher, key) => {
  const saltedBuffer = key.decrypt(cipher);
  console.log('************************');
  console.log(saltedBuffer);
  const buffer = saltedBuffer.slice(0, 16);
  return buffer.toString('hex');
};

/**
 * Asymmetrically encrypt a secret key with RSA.
 * @param aesSecret Buffer
 * @param rsaKey NodeRSA key object with public key.
 * @returns {String} Base64 cipher.
 */
const rsaEncryptAesKey = (aesSecret, rsaKey) => {
  const salt = genRdmBuffer(32);
  const buffer = Buffer.concat([aesSecret, salt]);
  return rsaKey.encrypt(buffer, 'base64');
};

/**
 * Asymmetrically decrypt a secret key with RSA.
 * @param cipher String Base64 cipher.
 * @param key NodeRSA key object with private key.
 * @returns {Buffer} Clear AES secret key.
 */
const rsaDecryptAesKey = (cipher, key) => {
  const saltedBuffer = key.decrypt(cipher);
  return saltedBuffer.slice(0, 32);
};

export { rsaDecryptAesKey, rsaDecryptUid, rsaEncryptAesKey, rsaEncryptUid };
