import { store } from '../index';
import { USERS } from './constants';
import NodeRSA from 'node-rsa';
import { rsaDecryptUid } from './rsa';

const getUserDetails = userHardCodedId => {
  const { docs, traderIds } = store.getState().db;
  const { name, rsaSecretKey } = USERS[userHardCodedId];
  // Import RSA key
  const rsaKey = new NodeRSA();
  rsaKey.importKey(rsaSecretKey, process.env.REACT_APP_ENCRYPT_KEY);
  // Get doc id
  const details = traderIds.map(id => docs[id]).filter(details => details.name === name)[0];
  const id = details.id;
  // Decrypt dismissedBids
  const encryptedDismissedBids = docs[id].dismissedBids || [];
  const dismissedBids = encryptedDismissedBids.map(encId => rsaDecryptUid(encId, rsaKey));
  return { rsaKey, dismissedBids, id };
};

export default getUserDetails;
