const CLEAR_IPFS_NAMES = 'CLEAR_IPFS_NAMES';
const SET_IPFS_NAMES = 'SET_IPFS_NAMES';

const clearIpfsNames = () => {
  return {
    type: CLEAR_IPFS_NAMES,
  };
};

const setIpfsNames = ipfsNames => {
  return {
    type: SET_IPFS_NAMES,
    payload: { ipfsNames },
  };
};

export { CLEAR_IPFS_NAMES, SET_IPFS_NAMES, clearIpfsNames, setIpfsNames };
