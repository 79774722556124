import React, { Fragment, Component } from 'react';
import { Box, Grommet } from 'grommet';
import { theme } from './theme';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
// import Roles from './components/Roles/Roles';
import NavBar from './components/NavBar/NavBar';
import Trading from './components/Trading/Trading';
import NewCAP from './components/NewCAP/NewCAP';
import IPFSWritingIndicator from './components/IPFSWritingIndicator/IPFSWritingIndicator';
import MyTrades from './components/MyTrades/MyTrades';
import TradingHistory from './components/TradingHistory/TradingHistory';
import Users from './components/Users/Users';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import Administration from './components/Administration/Administration';
import Forget from './components/Forget/Forget';
import { setLoggedUser } from './actions/logggedUserActions';
import { USERS } from './util/constants';
import getUserDetails from './util/getUserDetails';
import { setUser } from './actions/userActions';
import { parseFilesContent } from './actions/dbActions';
import { loadAllFilePaths } from './util/cargoUtil';
import ForgetPassword from './components/ForgetPassword/ForgetPassword';
import ResetPassword from './components/ForgetPassword/ResetPassword';
import Footer from './components/Footer/Footer';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // setLoggedUser('asdf', 'asdf',)
  }

  componentDidMount() {
    this.checkExistData();
    // this.props.setLoggedUser('terb', 'hgfdh', 'adsf', {});
  }

  checkExistData() {
    const existLoggedUserData = localStorage.getItem('loggedUser');
    if (!existLoggedUserData) {
      return;
    }
    const userData = JSON.parse(existLoggedUserData);
    this.props.setLoggedUser(
      userData?.token,
      userData?.user,
      userData?.session,
      userData?.otherDetails,
    );

    var selectedTradeType = 'British Petroleum';
    if (userData?.otherDetails?.tradeType) {
      selectedTradeType = userData?.otherDetails?.tradeType;
    }
    const selectedTrade = USERS.findIndex(x => x.name === selectedTradeType);
    this.onSelectTrade(selectedTrade);
  }

  onSelectTrade(hardCodedIdx) {
    const { rsaKey, dismissedBids, id } = getUserDetails(hardCodedIdx);
    // dispatch action to set user
    this.props.setUser(rsaKey, dismissedBids, id, hardCodedIdx);
    // dispatch action to parse DB files
    const filesContent = this.props.ipfsFilesIds.map(id => this.props.ipfsFilesContent[id]);
    loadAllFilePaths();
    this.props.parseFilesContent(filesContent, rsaKey);
  }

  render() {
    const { loggedUser } = this.props;

    return (
      <Grommet theme={theme}>
        <IPFSWritingIndicator />
        <BrowserRouter>
          <Box background={'primary'} height={('100vh', { min: '100vh' })}>
            <NavBar theme={theme} />
            <ResetPassword />
            {loggedUser?.token ? (
              <Switch>
                {loggedUser && loggedUser?.otherDetails?.role !== 'Admin' ? (
                  <Fragment>
                    <Route exact path="/" component={Trading} />
                    <Route exact path="/cap" component={NewCAP} />
                    <Route exact path="/trades" component={MyTrades} />
                    <Route exact path="/history" component={TradingHistory} />
                    <Route exact path="/user" component={Users} />
                    <Route exact path="/forget" component={Forget} />                   
                    <Redirect from="*" to="/" />
                  </Fragment>
                ) : loggedUser && loggedUser?.otherDetails?.role === 'Admin' ? (
                  <Fragment>
                    <Route exact path="/" component={Administration} />
                    <Route exact path="/administration/userlist" component={Administration} />
                    <Route exact path="/user" component={Users} />
                    <Redirect from="/administration/" to="/" />
                  </Fragment>
                ) : null}
              </Switch>
            ) : (
              <Switch>
                <Fragment>
                  <Route exact path="/login" component={Login} />
                  <Redirect to="/login" />
                  <Route exact path="/signup" component={Signup} />
                  <Route exact path="/forgetpassword" component={ForgetPassword} />
                </Fragment>
              </Switch>
            )}

            <Footer />
          </Box>
        </BrowserRouter>
      </Grommet>
    );
  }
}

App.propTypes = {
  userDetails: PropTypes.shape({
    name: PropTypes.string,
  }),
  loggedUser: PropTypes.shape({
    token: PropTypes.string,
    otherDetails: PropTypes.object,
  }),
  setLoggedUser: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  ipfsFilesContent: PropTypes.objectOf(PropTypes.string),
  ipfsFilesIds: PropTypes.arrayOf(PropTypes.string),
  parseFilesContent: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    userDetails: store.db.docs[store.user.id],
    loggedUser: store.loggedUser,
    ipfsFilesContent: store.db.ipfsFilesContent,
    ipfsFilesIds: store.db.ipfsFilesIds,
  };
};

const mapDispatchToProps = dispatch => ({
  setLoggedUser: (token, user, session, otherDetails) => {
    dispatch(setLoggedUser(token, user, session, otherDetails));
  },
  setUser: (rsaKey, dismissedBids, id, hardCodedIdx) => {
    dispatch(setUser(rsaKey, dismissedBids, id, hardCodedIdx));
  },
  parseFilesContent: (files, rsaPrivateKey) => {
    dispatch(parseFilesContent(files, rsaPrivateKey));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
