import axios from 'axios';
import { setLoggedUser } from '../../actions/logggedUserActions';
import { connect } from 'react-redux';
import { Box, Text } from 'grommet/es6';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ForgetPassword.module.scss';
import { showErrorToast, showSuccessToast } from '../../util/toast-service';

const ResetPassword = ({ setLoggedUser, loggedUser }) => {
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const isResetFlag = loggedUser?.otherDetails?.tmpPassword;
  if (isResetFlag) {
    const modalOptions = {
      show: true,
      backdrop: false,
    };
    window.$('#resetPassModal').modal(modalOptions);
  }

  async function submitResetPassword() {
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    if (!passPattern.test(password)) {
      showErrorToast(
        '<p><b>Invalid Password. </b></p> <p>Password should have one Uppercase, one number, one special character.</p> <p>It should have minimum 8 characters, maximum upto 20 characters. </p>',
        8000,
      );
      return;
    }

    if (password !== cpassword) {
      showErrorToast('<p>Password mismatched.</p>');
      return;
    }

    const passData = {
      email: loggedUser?.otherDetails?.email,
      password: password,
      tmpPassword: loggedUser?.otherDetails?.tmpPassword,
    };

    try {
      const { data } = await axios.post(`/user/changepassword`, passData);
      showSuccessToast(data?.message || 'Password reset successfully.');
      refreshProfile();
    } catch (e) {
      showErrorToast('Cannot submitted a request.');
      console.log('Error occured: ', e);
    }
  }

  async function refreshProfile() {
    const userDetails = await axios.get(`/user/profile`);
    const userObj = {
      token: loggedUser.token,
      user: loggedUser.user,
      session: loggedUser.session,
      otherDetails: userDetails?.data,
    };

    localStorage.setItem('loggedUser', JSON.stringify(userObj));
    await setLoggedUser(loggedUser.token, loggedUser.user, loggedUser.session, userDetails?.data);
    window.$('#resetPassModal').modal('toggle');
  }

  return (
    <div
      className="modal fade"
      id="resetPassModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="resetPassModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title colorBlack" id="resetPassModal">
              Reset Password
            </h5>
          </div>
          <form onSubmit={e => e.preventDefault()} className={styles.formWrapper}>
            <h3 className={styles.Text1}></h3>
            <div className={styles.main}>
              <Text className={styles.Text}>New Password</Text>
            </div>
            <input
              className={styles.inputbox1}
              type="password"
              placeholder="Enter new password"
              onChange={e => setPassword(e.target.value)}
            />
            <Text className={styles.Text}>Confirm Password</Text>
            <input
              className={styles.inputbox1}
              type="password"
              placeholder="Enter confirm password"
              onChange={e => setCPassword(e.target.value)}
            />
            <Box className={styles.signup_box}>
              <button type="submit" onClick={submitResetPassword} className={styles.submit}>
                Submit
              </button>
            </Box>
          </form>
          <div className="modal-footer d-none">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Deny
            </button>
            <button type="button" className="btn btn-primary">
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  loggedUser: PropTypes.shape({
    token: PropTypes.string,
    email: PropTypes.string,
    user: PropTypes.string,
    session: PropTypes.string,
    otherDetails: PropTypes.object,
  }),
  setLoggedUser: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    loggedUser: store.loggedUser,
  };
};

const mapDispatchToProps = dispatch => ({
  setLoggedUser: (token, user, session, otherDetails) => {
    dispatch(setLoggedUser(token, user, session, otherDetails));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
