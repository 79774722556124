// import React from 'react';
import { Box, Text } from 'grommet/es6';
import React, { useState } from 'react';
import styles from './ForgetPassword.module.scss';
import { connect } from 'react-redux';
import { setLoggedUser } from '../../actions/logggedUserActions';
import PropTypes from 'prop-types';
import { setUser } from '../../actions/userActions';
import { parseFilesContent } from '../../actions/dbActions';
import { useHistory } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../util/toast-service';
import axios from 'axios';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [isError] = useState(false);
  const [errorMsg] = useState('');

  const history = useHistory();

  async function submit() {
    const userPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!userPattern.test(email)) {
      showErrorToast('Invalid email address');
      return false;
    }

    try {
      const { data } = await axios.post(`/user/${email}/resetpassword`, {});
      console.log('data :>> ', data);
      showSuccessToast(data?.message || 'Temporary password sent to your email.');
      history.push('/login');
    } catch (e) {
      showErrorToast('Cannot submitted a request.');
      console.log('Error occured: ', e);
    }
    // history.push('/signup');
  }
  async function Back() {
    history.push('/login');
  }
  return (
    <div>
      <Box className={styles.welcomeBox}></Box>
      <Box className={styles.Box}>
        {isError ? (
          <div className={styles.errorMsg} dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
        ) : null}

        <form onSubmit={e => e.preventDefault()} className={styles.formWrapper}>
          <h3 className={styles.Text1}>Forget Password</h3>
          <br></br>

          <div className="">
            <div className={styles.main}>
              <Text className={styles.Text}>Email ID</Text>
              <input
                className={styles.option}
                type="text"
                placeholder="Enter Your Email ID"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>

          <Box className={styles.signup_box}>
            <button type="submit" onClick={submit} className={styles.signup}>
              Submit
            </button>
            <button type="submit" onClick={Back} className={styles.submit}>
              Back
            </button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

ForgetPassword.propTypes = {
  ipfsFilesContent: PropTypes.objectOf(PropTypes.string),
  ipfsFilesIds: PropTypes.arrayOf(PropTypes.string),
  setUser: PropTypes.func.isRequired,
  setLoggedUser: PropTypes.func.isRequired,
  parseFilesContent: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => ({
  setUser: (rsaKey, dismissedBids, id, hardCodedIdx) => {
    dispatch(setUser(rsaKey, dismissedBids, id, hardCodedIdx));
  },
  setLoggedUser: (token, user, session, otherDetails) => {
    dispatch(setLoggedUser(token, user, session, otherDetails));
  },
  parseFilesContent: (files, rsaPrivateKey) => {
    dispatch(parseFilesContent(files, rsaPrivateKey));
  },
});
const mapStateToProps = store => ({
  ipfsFilesContent: store.db.ipfsFilesContent,
  ipfsFilesIds: store.db.ipfsFilesIds,
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
