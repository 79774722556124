import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { gtcsOptions } from '../../../util/formFieldsUtils';

const GTC = ({ gtc, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box align="center" direction="row" justify="evenly">
            <Box width="100%" alignSelf="start">
              <FormField label="GT&Cs">
                <Select
                  plain
                  name="gtc"
                  placeholder="Select a GT&Cs"
                  value={gtc}
                  options={gtcsOptions.map(qo => (
                    <span key={qo.val}>{qo.lab}</span>
                  ))}
                  onChange={({ option }) => handleSelect('gtc', option)}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

GTC.propTypes = {
  gtc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default GTC;
