export const theme = {
  global: {
    colors: {
      primary: '#141935',
      secondary: '#343456',
      panel: '#222a42',
      header: '#eaeaea',
      disabled: '#707070',
      success: '#60b34f',
      danger: '#e84842',
      accent: '#625c88',
      focus: '#d6c9ff',
      'accent-1': '#d6c9ff',
      'accent-2': '#504377',
      'accent-3': '#27335A',
      thead: '#a9a9a9',
      brand: '#504376',
      selected: '#343457',
    },
    focus: {
      border: {
        color: 'accent',
      },
    },
  },
  icon: {
    size: {
      small: '14px',
    },
    extend: () => `
      margin-right: 10px;
    `,
  },
  formField: {
    border: {
      size: '0px',
    },
    extend: () => `
      label {
        margin-bottom: 12px;
      }
    `,
  },
  textInput: {
    extend: size => `
      background: white;
      border-radius: 0px;
      color: #444444;
      height: ${size !== 'large' ? '35px' : '50px'};
      font-size: ${size !== 'large' && '14px'};
    `,
  },
  select: {
    background: 'white',
    icons: {
      color: 'accent',
    },
    control: {
      extend: size => `
      >div {
        height: ${size !== 'large' ? '35px' : '50px'};
        font-weight: 600;
        padding-left: 11px;
        font-size: ${size !== 'large' && '14px'};
      }
    `,
    },
  },
  button: {
    border: {
      radius: '0px',
    },
  },
  table: {
    extend: () => `
      tbody td {
        border-bottom: 1px solid #504377;
        padding-bottom: 10px;
        padding-top: 10px;
      }
      margin-right: 10px;
    `,
  },
};
