import React, { useState } from 'react';
import TableArea from '../TableArea';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rangeToString } from '../../../util/util';

const Watchlist = ({
  tradePropsAsBuyerIds,
  docs,
  setCurrentBid,
  setCurrentAsk,
  setCurrentSale,
  userId,
}) => {
  const [search, setSearch] = useState('');

  // TODO DRY
  const sales = tradePropsAsBuyerIds
    .filter(tpId => !docs[tpId].saleId && docs[tpId]?.seller !== userId)
    .map(id => {
      const tradeProp = docs[id];
      const cargo = docs[tradeProp.cargo];
      return {
        id: tradeProp.id,
        name: cargo.name,
        seller: docs[tradeProp.seller].name,
        price: [tradeProp.priceFrom, tradeProp.priceTo],
        cargoSize: cargo.cargoSize,
        quality: cargo.quality,
        loadingRange: rangeToString([
          (new Date(cargo.loadingRangeStart).getTime() / 1000).toFixed(0),
          (new Date(cargo.loadingRangeEnd).getTime() / 1000).toFixed(0),
        ]),
        operationTolerance: tradeProp.operationTolerance,
        options: tradeProp.options,
        grade: cargo.grade,
        tradeProposition: null, // TODO, should be Bids instead
        bidSent: !!tradeProp.bidIds.length,
        txTime: tradeProp.unixTimestamp,
      };
    })
    .sort((a, b) => b.txTime - a.txTime);

  const columns = [
    {
      name: 'name',
      label: 'Tracking Number',
      weight: 'bold',
      size: '12px',
    },
    {
      name: 'seller',
      label: 'Seller',
      weight: 'normal',
      size: '13px',
    },
    {
      name: 'price',
      label: 'Differential Range',
      weight: 'normal',
      size: '13px',
    },
    {
      name: 'cargoSize',
      label: 'Quantity',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'grade',
      label: 'Grade',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'loadingRange',
      label: 'Loading range',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'operationTolerance',
      label: 'Operational Tolerance (%)',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'options',
      label: 'Options',
      weight: 'normal',
      size: '11px',
    },
  ];

  return (
    <TableArea
      title="Watchlist"
      columns={columns}
      data={sales}
      type="watchlist"
      setCurrentBid={setCurrentBid}
      setCurrentAsk={setCurrentAsk}
      setCurrentSale={setCurrentSale}
      search={search}
      setSearch={setSearch}
    />
  );
};

Watchlist.propTypes = {
  tradePropsAsBuyerIds: PropTypes.arrayOf(PropTypes.string),
  docs: PropTypes.objectOf(PropTypes.object),
  setCurrentBid: PropTypes.func.isRequired,
  setCurrentAsk: PropTypes.func.isRequired,
  setCurrentSale: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

const mapStateToProps = store => {
  return {
    tradePropsAsBuyerIds: store.db.tradePropsAsBuyerIds,
    docs: store.db.docs,
    userId: store.user.id,
  };
};

export default connect(mapStateToProps)(Watchlist);
