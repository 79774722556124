import { store } from '../index';
import { IPNS_KEY_NAME } from './constants';
import axios from 'axios';
import {
  parseFilesContent,
  setIpfsDirHash,
  setIpfsFiles,
  setIsWriting,
} from '../actions/dbActions';
import { certify } from './web3';
import getUserDetails from './getUserDetails';
import { setUser } from '../actions/userActions';

/**
 * Post new doc both locally and on IPFS.
 * In order to replace an existing doc, just re-use the same `doc.id`.
 * @param newDocs
 * @returns {Promise<void>}
 */
const postToDB = async (newDocs, action = '') => {
  // get current store state
  const { db, user } = store.getState();
  const { ipfsFilesContent, ipfsFilesIds } = db;
  const rsaPrivateKey = user.rsaKey;
  const userHardCodedIdx = user.hardCodedIdx;

  // set isWriting flag
  store.dispatch(setIsWriting(true));

  // push new files to the store
  const newFiles = newDocs.map(doc => ({
    name: doc.id,
    content: JSON.stringify(doc),
  }));
  for (const newFile of newFiles) {
    ipfsFilesIds.push(newFile.name);
    ipfsFilesContent[newFile.name] = newFile.content;
  }
  store.dispatch(setIpfsFiles(ipfsFilesIds, newFiles));

  // parse and store clear documents
  (async () => {
    const ipfsFilesContentArray = ipfsFilesIds.map(id => ipfsFilesContent[id]);
    await store.dispatch(parseFilesContent(ipfsFilesContentArray, rsaPrivateKey));
    const { rsaKey, dismissedBids, id } = getUserDetails(userHardCodedIdx);
    store.dispatch(setUser(rsaKey, dismissedBids, id, userHardCodedIdx));
  })();

  // write documents
  console.log('Writing documents');
  let filePaths = [];
  const writePromises = [];
  for (const fileId of ipfsFilesIds) {
    const fileContent = ipfsFilesContent[fileId];
    writePromises.push(
      axios.post('/ipfs', {
        name: fileId,
        content: fileContent,
      }),
    );
  }
  let writePromisesResponses;
  try {
    writePromisesResponses = await Promise.all(writePromises);
  } catch (e) {
    console.log('Failed to insert document: ', e);
    store.dispatch(setIsWriting(false));
    return;
  }
  for (const r of writePromisesResponses) {
    if (action === 'dismiss') {
      console.log('r :>> ', r);
    }
    const { data } = r;
    filePaths.push(data.ipfsPath);
  }

  // Take latest ipfsPaths for bidding
  if (action === 'bid') {
    var responseAllIpfs;
    try {
      responseAllIpfs = await axios.get(`/ipfs/name/${IPNS_KEY_NAME}`);
      if (responseAllIpfs) {
        const existingIpfs = responseAllIpfs.data.content;
        const existingIpfsObj = JSON.parse(existingIpfs);
        filePaths.push(...existingIpfsObj);
      }
    } catch (err) {
      console.log('Error occurred :>> ', err);
      return;
    }
    filePaths = [...new Set(filePaths)];
  }

  // set IPFS dir hash to the store
  console.log('Setting IPFS dir hash to the store');
  let ipfsPath = '';
  try {
    const { data } = await axios.post('/ipfs', {
      name: IPNS_KEY_NAME,
      content: JSON.stringify(filePaths),
    });
    ipfsPath = data.ipfsPath;
    store.dispatch(setIpfsDirHash(ipfsPath));
  } catch (e) {
    console.log('Failed to publish IPFS record: ', e);
    store.dispatch(setIsWriting(false));
    return;
  }

  // publishing IPNS record
  console.log(`Updating IPNS record: {keyName : ${IPNS_KEY_NAME}}`);
  try {
    axios.post('/ipns/publish', {
      keyName: IPNS_KEY_NAME,
      ipfsPath: ipfsPath,
    });
  } catch (e) {
    console.log('Failed to publish IPNS record: ', e);
    store.dispatch(setIsWriting(false));
    return;
  }

  // certify on the blockchain
  console.log('Certify on the blockchain');
  certify(ipfsPath);

  // release isWriting flag
  console.log('Finished posting');
  store.dispatch(setIsWriting(false));
};

const postToDBDismiss = async (newDocs, bidId = '') => {
  // get current store state
  const { db, user } = store.getState();
  const { ipfsFilesContent, ipfsFilesIds } = db;
  const rsaPrivateKey = user.rsaKey;
  const userHardCodedIdx = user.hardCodedIdx;

  // set isWriting flag
  store.dispatch(setIsWriting(true));

  // push new files to the store
  const newFiles = newDocs.map(doc => ({
    name: doc.id,
    content: JSON.stringify(doc),
  }));
  for (const newFile of newFiles) {
    ipfsFilesIds.push(newFile.name);
    ipfsFilesContent[newFile.name] = newFile.content;
  }
  store.dispatch(setIpfsFiles(ipfsFilesIds, newFiles));

  // parse and store clear documents
  (async () => {
    const ipfsFilesContentArray = ipfsFilesIds.map(id => ipfsFilesContent[id]);
    await store.dispatch(parseFilesContent(ipfsFilesContentArray, rsaPrivateKey));
    const { rsaKey, dismissedBids, id } = getUserDetails(userHardCodedIdx);
    store.dispatch(setUser(rsaKey, dismissedBids, id, userHardCodedIdx));
  })();

  // write documents
  console.log('Writing documents');

  const allIpfsNamesTmp = localStorage.getItem('ipfsNames');
  if (!allIpfsNamesTmp) {
    return;
  }
  const allIpfsNames = JSON.parse(allIpfsNamesTmp);
  const selectedPath = allIpfsNames[bidId];
  console.log('selectedPath :>> ', selectedPath);

  let filePaths = [];
  var responseAllIpfs;
  try {
    responseAllIpfs = await axios.get(`/ipfs/name/${IPNS_KEY_NAME}`);
    if (responseAllIpfs) {
      const existingIpfs = responseAllIpfs.data.content;
      const existingIpfsObj = JSON.parse(existingIpfs);
      filePaths.push(...existingIpfsObj);
    }
  } catch (err) {
    console.log('Error occurred :>> ', err);
    return;
  }

  filePaths = filePaths.filter(x => x !== selectedPath);
  console.log('Setting IPFS dir hash to the store');
  let ipfsPath = '';
  try {
    const { data } = await axios.post('/ipfs', {
      name: IPNS_KEY_NAME,
      content: JSON.stringify(filePaths),
    });
    ipfsPath = data.ipfsPath;
    store.dispatch(setIpfsDirHash(ipfsPath));
  } catch (e) {
    console.log('Failed to publish IPFS record: ', e);
    store.dispatch(setIsWriting(false));
    return;
  }

  // publishing IPNS record
  console.log(`Updating IPNS record: {keyName : ${IPNS_KEY_NAME}}`);
  try {
    axios.post('/ipns/publish', {
      keyName: IPNS_KEY_NAME,
      ipfsPath: ipfsPath,
    });
  } catch (e) {
    console.log('Failed to publish IPNS record: ', e);
    store.dispatch(setIsWriting(false));
    return;
  }

  // certify on the blockchain
  console.log('Certify on the blockchain');
  certify(ipfsPath);

  // release isWriting flag
  console.log('Finished posting');
  store.dispatch(setIsWriting(false));
};

export { postToDB, postToDBDismiss };
