const CLEAR_USER = 'CLEAR_USER';
const SET_USER = 'SET_USER';

const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

const setUser = (rsaKey, dismissedBids, id, hardCodedIdx) => {
  return {
    type: SET_USER,
    payload: { rsaKey, dismissedBids, id, hardCodedIdx },
  };
};

export { CLEAR_USER, SET_USER, clearUser, setUser };
