import { CLEAR_IPFS_NAMES, SET_IPFS_NAMES } from '../actions/ipfsNamesActions';

const initialState = {
  ipfsNames: {},
};

const ipfsNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IPFS_NAMES:
      return Object.assign({}, state, {
        ipfsNames: action.payload.ipfsNames,
      });
    case CLEAR_IPFS_NAMES:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};
export default ipfsNamesReducer;
