import toastr from 'toastr';

toastr.options = {
  positionClass: 'toast-top-center',
  hideDuration: 300,
  timeOut: 6000,
};

const showToast = (msg, timeout = 6000) => {
  toastr.clear();
  toastr.options.timeOut = timeout;
  toastr.info(msg);
};

const showSuccessToast = (msg, timeout = 6000) => {
  toastr.options.timeOut = timeout;
  toastr.clear();
  toastr.success(msg);
};

const showErrorToast = (msg, timeout = 6000) => {
  toastr.options.timeOut = timeout;
  toastr.clear();
  toastr.error(msg);
};

export { showToast, showSuccessToast, showErrorToast };
