import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, Heading, Text, TextInput, ResponsiveContext } from 'grommet';
import styles from '../Trading.module.scss';
// import ClientsSelector from '../../ClientsSelector/ClientsSelector';
import { benchmarkPrice } from '../../../util/formFieldsUtils';
import { postToDB } from '../../../util/postToDB';
import { TRADE_PROPOSITION_TYPE } from '../../../util/constants';
import { genUid } from '../../../util/util';
import { rsaEncryptAesKey, rsaEncryptUid } from '../../../util/rsa';
import { aesEncryptFloat, aesEncryptString } from '../../../util/aes';
import { connect } from 'react-redux';
import _ from 'lodash';
import { sendAuditLogEntry } from '../../../util/axios';

class Ask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetClients: [],
      priceFrom: 0,
      priceTo: 0,
      isAsking: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.currentAsk, this.props.currentAsk)) {
      this.setState({ priceFrom: 0, priceTo: 0 });
    }
  }

  submitTradeProposition = async () => {
    const { currentAsk, docs, resetCurrentAsk, userId, userRsaKey } = this.props;
    const { targetClients, priceFrom, priceTo } = this.state;
    // encapsulate key for target clients
    const cargoDoc = docs[currentAsk.id];
    const secret = cargoDoc.encapsulatedKey;
    // const buyersPublicKeys = targetClients.map(trader => trader.pk);
    const sellerPublicKey = userRsaKey;
    // create TradeProposition document
    const tradeProposition = {
      type: TRADE_PROPOSITION_TYPE,
      id: genUid(),
      timestamp: new Date().getTime(),
      // buyersEncapsulatedKey: buyersPublicKeys.map(pk => rsaEncryptAesKey(secret, pk)),
      sellerEncapsulatedKey: rsaEncryptAesKey(secret, sellerPublicKey),
      buyers: targetClients.map(trader => rsaEncryptUid(trader.id, sellerPublicKey)),
      seller: aesEncryptString(userId, secret),
      cargo: aesEncryptString(cargoDoc.id, secret),
      priceFrom: aesEncryptFloat(priceFrom, secret),
      priceTo: aesEncryptFloat(priceTo, secret),
      operationTolerance: aesEncryptFloat(cargoDoc.operationTolerance, secret),
      options: aesEncryptFloat(cargoDoc.options, secret),
      deliveryTerms: aesEncryptFloat(cargoDoc.deliveryTerms, secret),
      gtc: aesEncryptFloat(cargoDoc.gtc, secret),
    };
    this.setState({ targetClients: [], isAsking: false });
    resetCurrentAsk();
    await postToDB([tradeProposition]);
    sendAuditLogEntry('ASK');
  };

  render() {
    const { priceFrom, priceTo, isAsking } = this.state;
    const { currentAsk, isWriting } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
            <Box align="baseline" direction="row" justify="between">
              <Box direction="row" align="center">
                <Heading
                  level={size !== 'large' ? 4 : 3}
                  margin={{ top: size !== 'large' ? '5px' : 'small' }}
                >
                  Ask
                </Heading>
              </Box>
            </Box>
            <Box height="100%" justify="between">
              <Box>
                <Box direction="row" justify="between">
                  <Box>
                    <Box direction="row" margin={{ bottom: 'medium' }} align="center">
                      <Text
                        size={size !== 'large' ? '13px' : '17px'}
                        color={'thead'}
                        weight="normal"
                        margin={{ right: size !== 'large' ? 'small' : 'large' }}
                      >
                        Balance
                      </Text>
                      <Text weight="bold" size={size !== 'large' ? '12px' : '16px'}>
                        {currentAsk ? currentAsk.lastPrice : 'N/A'} $US
                      </Text>
                    </Box>
                    {/* <Box>
                      <Text
                        size={size !== 'large' ? '13px' : '17px'}
                        color={'thead'}
                        weight="normal"
                      >
                        Sell product to
                      </Text>
                      <Box
                        margin={{ vertical: size !== 'large' ? '5px' : 'medium' }}
                        width={size !== 'large' ? '200px' : '280px'}
                        className={size !== 'large' ? styles['ask-small-screen'] : ''}
                      >
                        <ClientsSelector
                          targetClients={targetClients}
                          handleSelect={clients => this.setState({ targetClients: clients })}
                          isAsk={true}
                          disabled={!currentAsk}
                        />
                      </Box>
                    </Box> */}
                  </Box>
                  <Box width="50%">
                    <Text
                      size={size !== 'large' ? '13px' : '17px'}
                      color={'thead'}
                      weight="normal"
                      margin={{ bottom: 'small' }}
                    >
                      Price Differential Range
                    </Text>
                    <Box direction="row" align="center">
                      {currentAsk ? (
                        <React.Fragment>
                          <Box
                            className={`${styles['ask-price']} ${size !== 'large' &&
                              styles['small-screen']}`}
                          >
                            <FormField
                              label="from"
                              className={`${size !== 'large' && styles['ask-form-field']} ${
                                styles['small-screen']
                              }`}
                            >
                              <TextInput
                                value={priceFrom}
                                className={styles['ask-input']}
                                type="number"
                                onChange={e => this.setState({ priceFrom: e.target.value })}
                                step="0.01"
                                min={0}
                                max={benchmarkPrice(currentAsk.grade)}
                              />
                            </FormField>
                            <FormField label="to" className={styles['ask-form-field']}>
                              <TextInput
                                value={priceTo}
                                className={styles['ask-input']}
                                type="number"
                                onChange={e => this.setState({ priceTo: e.target.value })}
                                step="0.01"
                                min={benchmarkPrice(currentAsk.grade)}
                              />
                            </FormField>
                          </Box>
                          <Heading
                            level={size !== 'large' ? 5 : 4}
                            margin={{ left: size !== 'large' ? '10px' : '20px' }}
                          >
                            {`${benchmarkPrice(currentAsk.grade)} $US`}
                          </Heading>
                        </React.Fragment>
                      ) : (
                        <Heading level={size !== 'large' ? 4 : 3} color={'disabled'} margin="0px">
                          Select a product to ask or a sale to see the offers.
                        </Heading>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box height="37px" margin={{ bottom: '15px' }}>
                <Button
                  fill
                  color={!currentAsk ? 'disabled' : 'danger'}
                  primary
                  disabled={
                    isWriting ||
                    parseFloat(priceFrom, 10) > benchmarkPrice(currentAsk?.grade) ||
                    parseFloat(priceTo, 10) < benchmarkPrice(currentAsk?.grade) ||
                    isAsking
                  }
                  className={styles['ask-bid-btn']}
                  onClick={() => {
                    this.setState({ isAsking: true }, this.submitTradeProposition);
                  }}
                >
                  <Text size="14px" weight="bold">
                    ASK
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

Ask.propTypes = {
  docs: PropTypes.object,
  currentAsk: PropTypes.object,
  resetCurrentAsk: PropTypes.func.isRequired,
  userRsaKey: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isWriting: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    docs: store.db.docs,
    userId: store.user.id,
    userRsaKey: store.user.rsaKey,
    isWriting: store.db.isWriting,
  };
};

export default connect(mapStateToProps)(Ask);
