import { store } from '../index';

const onBeforeUnload = e => {
  const { db } = store.getState();
  const { isWriting } = db;
  if (isWriting) {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = 'Data may be lost if you leave the page, are you sure?';
  } else {
    delete e.returnValue;
  }
};

export default onBeforeUnload;
