import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Text, TextInput } from 'grommet';
import { benchmarkPrice } from '../../../util/formFieldsUtils';

const PriceRange = ({ minPrice, maxPrice, grade, handleChange }) => {
  return (
    <Box margin={{ top: 'medium' }}>
      <FormField label="Expected price differential range per barrel vs Dated Brent">
        <Box align="center" direction="row" justify="start">
          <Box width="30%">
            <Box width="100%" alignSelf="start">
              <TextInput
                disabled={!grade}
                color={'primary'}
                name="minPrice"
                value={minPrice || ''}
                onChange={handleChange}
                placeholder="Minimum"
                type="number"
                step="0.01"
                min={0}
                max={grade ? benchmarkPrice(grade.key) : undefined}
              />
            </Box>
          </Box>
          <Box align="center" justify="center" width="33%" direction="row">
            <Box height="2px" background={'white'} width="50px" margin={{ horizontal: '8px' }} />
            {grade ? (
              <Text size="large" weight="bold" textAlign="center">
                {benchmarkPrice(grade.key)
                  ? `${benchmarkPrice(grade.key).toString()}$`
                  : 'Select a grade'}
              </Text>
            ) : (
              <Text size="medium" textAlign="center">
                Select a grade
              </Text>
            )}
            <Box height="2px" background={'white'} width="50px" margin={{ horizontal: '8px' }} />
          </Box>
          <Box width="30%">
            <Box width="100%">
              <TextInput
                disabled={!grade}
                color={'primary'}
                name="maxPrice"
                value={maxPrice || ''}
                onChange={handleChange}
                placeholder="Maximum"
                type="number"
                step="0.001"
                min={grade ? benchmarkPrice(grade.key) : undefined}
              />
            </Box>
          </Box>
        </Box>
      </FormField>
    </Box>
  );
};

PriceRange.propTypes = {
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  grade: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PriceRange;
