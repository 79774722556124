import React, { Fragment } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import SideBar from './SideBar';
import Userlist from '../UserList/UserList';
import Auditlog from '../Auditlog/Auditlog';
import NewUser from './../Users/NewUser';

const Administration = () => {
  return (
    <BrowserRouter>
      <SideBar>
        <Switch>
          <Fragment>
            <Route path="/administration/userlist" component={Userlist} />
            <Route path="/auditlog" component={Auditlog} />
            <Route exact path="/administration/newuser" component={NewUser} />
            <Redirect from="*" to="/administration/userlist" />
          </Fragment>
        </Switch>
      </SideBar>
    </BrowserRouter>
  );
};
Administration.propTypes = {};

export default Administration;
