import ScLabel from '../components/NewCAP/FormFields/SCLabel';
import React from 'react';
import {
  BP,
  CHEVRON,
  CONOCO_PHILLIPS,
  DIFF_DATED_BRENT,
  DIFF_URALS,
  EQUINOR,
  EXXONMOBIL,
  FLAT,
  HEAVY,
  LIGHT,
  MEDIUM,
  SHELL,
  TOTAL,
  PANAMAX,
  AFRAMAX,
  SUEZMAX,
  VLCC,
  ULCC,
  ACTIVE,
  INACTIVE,
  USER,
  ADMIN,
} from './constants';

const gradeOptions = [
  { lab: 'Brent (UK)', val: 'BRENT-UK', min: 20 },
  { lab: 'Mariner Blend (UK)', val: 'MARINER BLEND-UK', min: 20 },
  { lab: 'Heidrun (Norway)', val: 'HEIDRUN-Norway', min: 10 },
  { lab: 'Martin Linge (Norway)', val: 'MARTIN LINGE-Norway', min: 10 },
  { lab: 'Johan Sverdrup (Norway)', val: 'JOHAN SVERDRUP-Norway', min: 10 },
  { lab: 'Grane Blend (Norway)', val: 'GRANE BLEND-Norway', min: 10 },
  { lab: 'Norne Blend (Norway)', val: 'NORNE BLEND-Norway', min: 10 },
  { lab: 'Troll Blend (Norway)', val: 'TROLL BLEND-Norway', min: 10 },
  { lab: 'Ekofisk Blend (Norway)', val: 'EKOFISK BLEND-Norway', min: 10 },
  { lab: 'Goliat Blend (Norway)', val: 'GOLIAT BLEND-Norway', min: 10 },
  { lab: 'Gullfaks Blend (Norway)', val: 'GULLFAKS BLEND-Norway', min: 10 },
  { lab: 'Statfjord  (Norway)', val: 'STATFJORD -Norway', min: 10 },
  { lab: 'Oseberg Blend (Norway)', val: 'OSEBERG BLEND-Norway', min: 10 },
  { lab: 'Forties Blend (UK)', val: 'FORTIES BLEND-UK', min: 20 },
  { lab: 'Draugen (Norway)', val: 'DRAUGEN-Norway', min: 10 },
  { lab: 'Aasta Hansteen (Norway)', val: 'AASTA HANSTEEN-Norway', min: 10 },
  { lab: 'Jotun (Norway)', val: 'JOTUN-Norway', min: 10 },
  { lab: 'Gina Krog (Norway)', val: 'GINA KROG-Norway', min: 10 },
  { lab: 'Njord (Norway)', val: 'NJORD-Norway', min: 10 },
  { lab: 'Aasgard Blend (Norway)', val: 'AASGARD BLEND-Norway', min: 10 },
  { lab: 'Skarv (Norway)', val: 'SKARV-Norway', min: 10 },
  { lab: 'Gudrun Blend (Norway)', val: 'GUDRUN BLEND-Norway', min: 10 },
  { lab: 'Ormen Lange Cond. (Norway)', val: 'ORMEN LANGE COND.-Norway', min: 10 },
  { lab: 'Snohvit Cond. (Norway)', val: 'SNOHVIT COND.-Norway', min: 10 },
  { lab: 'Edvard Grieg (Norway)', val: 'EDVARD GRIEG-Norway', min: 10 },
  { lab: 'Alvheim Blend (Norway)', val: 'ALVHEIM BLEND-Norway', min: 10 },
  { lab: 'Ula (Norway)', val: 'ULA-Norway', min: 10 },
  { lab: 'Valhall (Norway)', val: 'VALHALL-Norway', min: 10 },
  { lab: 'Ivar Aasen (Norway)', val: 'IVAR AASEN-Norway', min: 10 },
  { lab: 'Schiehallion (UK)', val: 'SCHIEHALLION-UK', min: 20 },
  { lab: 'Clair (UK)', val: 'CLAIR-UK', min: 20 },
  { lab: 'Culzean (UK)', val: 'CULZEAN-UK', min: 20 },
  { lab: 'Harding (UK)', val: 'HARDING-UK', min: 20 },
  { lab: 'Dumbarton (UK)', val: 'DUMBARTON-UK', min: 20 },
  { lab: 'Flotta Gold (UK)', val: 'FLOTTA GOLD-UK', min: 20 },
  { lab: 'Gryphon (UK)', val: 'GRYPHON-UK', min: 20 },
  { lab: 'DUC (Denmark)', val: 'DUC-Denmark', min: 10 },
];

const gradeDictionnary = {
  TROLL: 'Troll',
  FORTIES: 'Forties',
  OSEBERG: 'Oseberg',
};

const qualityOptions = [
  {
    lab: 'Light',
    val: LIGHT,
  },
  {
    lab: 'Medium',
    val: MEDIUM,
  },
  {
    lab: 'Heavy',
    val: HEAVY,
  },
];

const qualityDictionnary = {
  LIGHT: 'Light',
  MEDIUM: 'Medium',
  HEAVY: 'Heavy',
};

const active = [
  {
    label: <ScLabel size="" title="Active" desc=")" />,
    value: ACTIVE,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="Inactive" desc=")" />,
    value: INACTIVE,
    disabled: false,
  },
];

const role = [
  {
    label: <ScLabel size="" title="User" desc=")" />,
    value: USER,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="Admin" desc=")" />,
    value: ADMIN,
    disabled: false,
  },
];

const shippingConstraintOptions = [
  {
    label: <ScLabel size="" title="Panamax" desc=")" />,
    value: PANAMAX,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="Aframax" desc="" />,
    value: AFRAMAX,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="Suezmax" desc="" />,
    value: SUEZMAX,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="VLCC" desc="" />,
    value: VLCC,
    disabled: false,
  },
  {
    label: <ScLabel size="" title="ULCC" desc="" />,
    value: ULCC,
    disabled: false,
  },
];

const shippingConstraintDictionnary = {
  FRONTLINE: 'Frontline',
  TEEKAY: 'Teekay',
  MAERSK: 'Maersk',
};

const benchmarkOptions = [
  {
    lab: 'Flat',
    val: FLAT,
  },
  {
    lab: 'Dated Brent',
    val: DIFF_DATED_BRENT,
  },
  {
    lab: 'Differential to Johan Sverdrup',
    val: DIFF_URALS,
  },
];

const benchmarkDictionnary = {
  FLAT: 'Flat',
  DIFF_DATED_BRENT: 'Differential dated Brent',
  DIFF_URALS: 'Differential to Johan Sverdrup',
};

const refineryOptions = [
  {
    lab: 'British Petroleum',
    val: BP,
  },
  {
    lab: 'Shell',
    val: SHELL,
  },
  {
    lab: 'Total',
    val: TOTAL,
  },
  {
    lab: 'Equinor',
    val: EQUINOR,
  },
  {
    lab: 'Conoco Phillips',
    val: CONOCO_PHILLIPS,
  },
  {
    lab: 'Chevron',
    val: CHEVRON,
  },
  {
    lab: 'ExxonMobil',
    val: EXXONMOBIL,
  },
];

const OperationalToleranceOptions = [
  {
    lab: '0',
    val: '0',
  },
  {
    lab: '(+/-) 5',
    val: '(+/-) 5',
  },
  {
    lab: '(+/-) 10',
    val: '(+/-) 10',
  },
];

const optionsInCargo = [
  {
    lab: 'Seller’s Option',
    val: 'Seller’s Option',
  },
  {
    lab: 'Buyer’s Option',
    val: 'Buyer’s Option',
  },
];

const shippingContractOptions = [
  {
    lab: 'Voyage Charter',
    val: 'Voyage Charter',
  },
  {
    lab: 'Time Charter',
    val: 'Time Charter',
  },
  {
    lab: 'Shuttle Tanker',
    val: 'Shuttle Tanker',
  },
];
const deliveryTermsOptions = [
  {
    lab: 'FOB',
    val: 'FOB',
  },
  {
    lab: 'CIF',
    val: 'CIF',
  },
  {
    lab: 'CFR',
    val: 'CFR',
  },
  {
    lab: 'DAP',
    val: 'DAP',
  },
  {
    lab: 'DES',
    val: 'DES',
  },
];
const pricingOptions = [
  {
    lab: 'Fixed',
    val: 'Fixed',
  },
  {
    lab: '5 days after B/L',
    val: 'Floating',
  },
  {
    lab: '2-1-2 around B/L',
    val: 'Floating',
  },
  {
    lab: '3 days average around B/L',
    val: 'Floating',
  },
  {
    lab: 'Custom option with date to choose',
    val: 'Floating',
  },
];
const gtcsOptions = [
  {
    lab: 'Equinor',
    val: 'Equinor',
  },
  {
    lab: 'TotalEnergies',
    val: 'TotalEnergies',
  },
  {
    lab: 'Aker BP',
    val: 'Aker BP',
  },
  {
    lab: 'Shell',
    val: 'Shell',
  },
  {
    lab: 'Exxonmobil',
    val: 'Exxonmobil',
  },
  {
    lab: 'ConocoPhillips',
    val: 'ConocoPhillips',
  },
  {
    lab: 'Chevron',
    val: 'Chevron',
  },
  {
    lab: 'BP',
    val: 'BP',
  },
];

const refineryDictionnary = {
  BP: 'British Petroleum',
  SHELL: 'Shell',
  TOTAL: 'Total',
  EQUINOR: 'Equinor',
  CONOCO_PHILLIPS: 'Conoco Phillips',
  CHEVRON: 'Chevron',
  EXXONMOBIL: 'ExxonMobil',
};

const benchmarkPrice = grade => {
  const allGrades = {};
  gradeOptions.forEach(gradeOpt => {
    allGrades[gradeOpt.val] = gradeOpt.min;
  });

  return allGrades[grade] || 0;

  // switch (grade) {
  //   case TROLL:
  //     return 75;
  //   case FORTIES:
  //     return 70;
  //   case OSEBERG:
  //     return 80;
  //   case 'BRENT-UK':
  //     return 80;
  //   default:
  //     return 0;
  // }
};

export {
  gradeOptions,
  qualityOptions,
  optionsInCargo,
  shippingConstraintOptions,
  shippingContractOptions,
  benchmarkOptions,
  benchmarkPrice,
  active,
  role,
  deliveryTermsOptions,
  gtcsOptions,
  pricingOptions,
  refineryOptions,
  gradeDictionnary,
  qualityDictionnary,
  benchmarkDictionnary,
  refineryDictionnary,
  shippingConstraintDictionnary,
  OperationalToleranceOptions,
};
