const SET_NEW_FETCHED_CIDS = 'SET_NEW_FETCHED_CIDS';
const SET_IS_WRITING = 'SET_IS_WRITING';
const SET_IPFS_DIR_HASH = 'SET_IPFS_DIR_HASH';
const SET_IPFS_FILES = 'SET_IPFS_FILES';
const PARSE_FILES_CONTENT = 'PARSE_FILES_CONTENT';

const setNewCidToNames = newCidsToNames => {
  return {
    type: SET_NEW_FETCHED_CIDS,
    payload: { newCidsToNames },
  };
};

const setIsWriting = isWriting => {
  return {
    type: SET_IS_WRITING,
    payload: { isWriting },
  };
};

const setIpfsDirHash = ipfsDirHash => {
  return {
    type: SET_IPFS_DIR_HASH,
    payload: { ipfsDirHash },
  };
};

const setIpfsFiles = (filesIds, newFiles) => {
  return {
    type: SET_IPFS_FILES,
    payload: { filesIds, newFiles },
  };
};

const parseFilesContent = (filesContent, rsaPrivateKey = undefined) => {
  return {
    type: PARSE_FILES_CONTENT,
    payload: { filesContent, rsaPrivateKey },
  };
};

export {
  SET_NEW_FETCHED_CIDS,
  SET_IS_WRITING,
  SET_IPFS_DIR_HASH,
  SET_IPFS_FILES,
  PARSE_FILES_CONTENT,
  setNewCidToNames,
  setIsWriting,
  setIpfsDirHash,
  setIpfsFiles,
  parseFilesContent,
};
