import { Button } from 'grommet';
import React, { children } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Administration.module.scss';

// import{FaBars} from "react-icons/fa";
const SideBar = ({ children }) => {
  const menuItem = [
    {
      path: '/administration/userlist',
      name: 'UserList',
    },
    {
      path: '/auditlog',
      name: 'Auditlog',
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={styles.top_section}>
          <h2 className={styles.admin_heading}>Administration</h2>
          <div className={styles.bars}>{/* <FaBars/> */}</div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink to={item.path} key={index} className={styles.link1} activeClassName= {styles.active} >
           {item.name}
          </NavLink>
        ))}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default SideBar;
