import { genRdmBuffer } from './util';
import crypto from 'crypto';

const IV_LENGTH = 16; // For AES, this is always 16

/**
 * Generate a random secret for AES encryption
 * @returns {Buffer} Random secret.
 */
const generateAesKey = () => genRdmBuffer(32);

/**
 * Symmetrically encrypt a string with AES. Returns the cipher appended to the `iv`, both base64 encoded.
 * @param text String
 * @param key Buffer
 * @returns {string} Cipher in the following form <base64>:<base64>. (cipher appended to the `iv`).
 */
const aesEncrypt = (text, key) => {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString('base64') + ':' + encrypted.toString('base64');
};

/**
 * Symmetrically decrypt an AES cipher.
 * @param cipher String Cipher in the following form <base64>:<base64>. (cipher appended to the `iv`).
 * @param key Buffer
 * @returns {string} Clear text.
 */
const aesDecrypt = (cipher, key) => {
  const cipherParts = cipher.split(':');
  const iv = Buffer.from(cipherParts.shift(), 'base64');
  const encryptedText = Buffer.from(cipherParts.join(':'), 'base64');
  const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
};

/**
 * Symmetrically encrypt a string.
 * @param s String
 * @param key Buffer
 * @returns {string} Cipher.
 */
const aesEncryptString = (s, key) => aesEncrypt(s, key);

/**
 * Symmetrically decrypt a string.
 * @param cipher String
 * @param key Buffer
 * @returns {string} Clear text.
 */
const aesDecryptString = (cipher, key) => aesDecrypt(cipher, key);

/**
 * Symmetrically encrypt an integer.
 * @param i Number
 * @param key Buffer
 * @returns {string} Cipher.
 */
const aesEncryptInt = (i, key) => aesEncrypt(i.toString(), key);

/**
 * Symmetrically decrypt an integer.
 * @param cipher String
 * @param key Buffer
 * @returns {number} Clear number.
 */
const aesDecryptInt = (cipher, key) => parseInt(aesDecrypt(cipher, key), 10);

/**
 * Symmetrically encrypt a float.
 * @param f Number
 * @param key Buffer
 * @returns {string} Cipher.
 */
const aesEncryptFloat = (f, key) => aesEncrypt(f.toString(), key);

/**
 * Symmetrically decrypt a float.
 * @param cipher String
 * @param key Buffer
 * @returns {number} Clear number.
 */
const aesDecryptFloat = (cipher, key) => parseFloat(aesDecrypt(cipher, key));

/**
 * Symmetrically encrypt a date.
 * @param d Date
 * @param key Buffer
 * @returns {string} Cipher.
 */
const aesEncryptDate = (d, key) => aesEncryptInt(d.getTime(), key);

/**
 * Symmetrically decrypt a date.
 * @param cipher String
 * @param key Buffer
 * @returns {Date} Clear date.
 */
const aesDecryptDate = (cipher, key) => new Date(aesDecryptInt(cipher, key));

export {
  aesDecryptDate,
  aesDecryptInt,
  aesDecryptFloat,
  aesDecryptString,
  aesEncryptDate,
  aesEncryptInt,
  aesEncryptFloat,
  aesEncryptString,
  generateAesKey,
};
