import React, { useState } from 'react';
import TableArea from '../TableArea';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rangeToString } from '../../../util/util';

const Sales = ({
  tradePropsAsSellerIds,
  docs,
  setCurrentBid,
  setCurrentAsk,
  setCurrentSale,
  selected,
  userId,
  ipfsNames,
}) => {
  const [search, setSearch] = useState('');

  const sales = tradePropsAsSellerIds
    .filter(tpId => !docs[tpId].saleId && docs[tpId]?.seller === userId)
    .map(id => {
      const tradeProp = docs[id];
      const cargo = docs[tradeProp.cargo];
      return {
        id: tradeProp.id,
        name: cargo.name,
        price: [tradeProp.priceFrom, tradeProp.priceTo],
        cargoSize: cargo.cargoSize,
        grade: cargo.grade,
        loadingRange: rangeToString([
          (new Date(cargo.loadingRangeStart).getTime() / 1000).toFixed(0),
          (new Date(cargo.loadingRangeEnd).getTime() / 1000).toFixed(0),
        ]),
        bidIds: tradeProp.bidIds,
        txTime: tradeProp.unixTimestamp,
      };
    })
    .sort((a, b) => b.txTime - a.txTime);

  const columns = [
    {
      name: 'name',
      label: 'Tracking Number',
      weight: 'bold',
      size: '12px',
    },
    {
      name: 'price',
      label: 'Differential Range',
      weight: 'normal',
      size: '13px',
    },
    {
      name: 'cargoSize',
      label: 'Quantity',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'grade',
      label: 'Grade',
      weight: 'normal',
      size: '11px',
    },
    {
      name: 'loadingRange',
      label: 'Loading range',
      render: datum => rangeToString(datum.loadingRange),
      weight: 'normal',
      size: '11px',
    },
  ];

  return (
    <TableArea
      title="My sales"
      columns={columns}
      data={sales}
      type="sales"
      setCurrentBid={setCurrentBid}
      setCurrentAsk={setCurrentAsk}
      setCurrentSale={setCurrentSale}
      selected={selected}
      search={search}
      setSearch={setSearch}
    />
  );
};

Sales.propTypes = {
  tradePropsAsSellerIds: PropTypes.arrayOf(PropTypes.string),
  docs: PropTypes.objectOf(PropTypes.object),
  setCurrentBid: PropTypes.func.isRequired,
  setCurrentAsk: PropTypes.func.isRequired,
  setCurrentSale: PropTypes.func.isRequired,
  selected: PropTypes.object,
  userId: PropTypes.string,
};

const mapStateToProps = store => {
  return {
    tradePropsAsSellerIds: store.db.tradePropsAsSellerIds,
    docs: store.db.docs,
    userId: store.user.id,
    ipfsNames: store.db.ipfsNames,
  };
};

export default connect(mapStateToProps)(Sales);
