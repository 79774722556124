import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, RadioButtonGroup } from 'grommet';
import { shippingConstraintOptions } from '../../../util/formFieldsUtils';
import styles from './FormFields.module.scss';

const ShippingConstraint = ({ cargoSize }) => {
  return (
    <Box margin={{ top: 'medium' }}>
      <Box>
        <Box width="100%">
          <FormField label="Shipping constraint" className={styles['radio-group']}>
            <RadioButtonGroup
              name="shippingConstraint"
              options={shippingConstraintOptions}
              // value={
              //   // TODO DRY
              //   cargoSize < 500000
              //     ? shippingConstraintOptions[0].value
              //     : cargoSize < 1500000
              //     ? shippingConstraintOptions[1].value
              //     : shippingConstraintOptions[2].value
              // }
              onChange={() => {}}
            />
          </FormField>
        </Box>
      </Box>
    </Box>
  );
};

ShippingConstraint.propTypes = {
  cargoSize: PropTypes.isRequired,
};

export default ShippingConstraint;
