import bp from '../media/logos/bp.png';
import shell from '../media/logos/shell.png';
import vitol from '../media/logos/vitol.png';
// import glencore from '../media/logos/glencore.png';
import akerbp from '../media/logos/aker-bp.png';
import equinor from '../media/logos/equinor.png';
import exxonmobil from '../media/logos/exxonmobil.png';

const rolesLogos = {
  'British Petroleum': bp,
  Shell: shell,
  Vitol: vitol,
  // Glencore: glencore,
  AkerBP: akerbp,
  Equinor: equinor,
  ExxonMobil: exxonmobil,
};

export { rolesLogos };
