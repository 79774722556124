import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { benchmarkOptions } from '../../../util/formFieldsUtils';

const BenchmarkRefinery = ({ benchmark, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: 'medium' }}>
          <Box align="center" direction="row" justify="evenly">
            <Box width="100%" alignSelf="start">
              <FormField label="Benchmark">
                <Select
                  plain
                  name="benchmark"
                  placeholder="Select a benchmark"
                  value={benchmark}
                  options={benchmarkOptions.map(bo => (
                    <span key={bo.val}>{bo.lab}</span>
                  ))}
                  onChange={({ option }) => handleSelect('benchmark', option)}
                  replace={false}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

BenchmarkRefinery.propTypes = {
  benchmark: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default BenchmarkRefinery;
