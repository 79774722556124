import Pusher from 'pusher-js';
import { IPNS_KEY_NAME, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from './constants';
import { store } from '../index';
import { parseFilesContent, setIpfsFiles, setNewCidToNames } from '../actions/dbActions';
import axios from 'axios';
import getUserDetails from './getUserDetails';
import { setUser } from '../actions/userActions';

const initPusher = () => {
  const socket = new Pusher("fd187b42f18c14a5b5bb", {
    cluster: "eu",
  });

  const channel = socket.subscribe('main-channel');

  channel.bind('new-ipns-record', onNewIpnsRecord);
};

const onNewIpnsRecord = async ({ keyName, ipfsPath, filesListContent }) => {
  // check if a new IPFS path was published
  if (keyName !== IPNS_KEY_NAME) return;
  const { db, user } = store.getState();
  if (db.ipfsDirPath === ipfsPath) return;
  console.log('New IPFS dir path published: ', ipfsPath);

  // list files
  console.log('Listing files');
  const filePaths = JSON.parse(filesListContent);

  // filter new files
  const newFilePaths = filePaths.filter(path => !db.cidToNames[path]);
  if (newFilePaths.length < 1) {
    console.log('No unknown file. Returning.');
    return;
  }

  // get new files content
  console.log('Getting new files');
  const getPromises = [];
  let getPromisesResponse;
  const newFiles = [];
  const newCidToNames = {};
  for (const filePath of newFilePaths) {
    getPromises.push(axios.get(`/ipfs/${filePath}`));
  }
  try {
    getPromisesResponse = await Promise.all(getPromises);
  } catch (e) {
    console.log('Failed to get documents: ', e);
    return;
  }
  for (let i = 0; i < getPromisesResponse.length; i++) {
    const r = getPromisesResponse[i];
    const filePath = newFilePaths[i];
    const { data } = r;
    const { name, content } = data;
    newFiles.push({ name, content });
    newCidToNames[filePath] = name;
  }

  // store raw files
  const filesIds = filePaths.map(cid => db.cidToNames[cid] || newCidToNames[cid]);
  store.dispatch(setIpfsFiles(filesIds, newFiles));
  store.dispatch(setNewCidToNames(newCidToNames));

  // parse and store clear documents
  const filesContent = [];
  for (const id of filesIds) {
    if (db.ipfsFilesContent[id]) {
      filesContent.push(db.ipfsFilesContent[id]);
    } else {
      const filteredNewFiles = newFiles.filter(f => f.name === id);
      if (filteredNewFiles.length < 1) continue;
      filesContent.push(filteredNewFiles[0].content);
    }
  }
  const rsaPrivateKey = user.rsaKey;
  await store.dispatch(parseFilesContent(filesContent, rsaPrivateKey));

  const userHardCodedIdx = user.hardCodedIdx;
  if (userHardCodedIdx >= 0) {
    const { rsaKey, dismissedBids, id } = getUserDetails(userHardCodedIdx);
    store.dispatch(setUser(rsaKey, dismissedBids, id, userHardCodedIdx));
  }
};

export default initPusher;
