import React from 'react';
import styles from './Footer.module.scss';
import './Footer.css';

const Footer = () => (
  <div>
    <div className="empty-height"></div>
    <footer1 className={styles.footertop}>
      <div className="col-md-12 col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div className="row">
          <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 col-xl-4 footerclass pt-3 text-left">
            <img
              alt="Arbopen"
              className="StyledImage-sc-ey4zx9-0 bUBFOe NavBar_logo_img__1do1m"
              src="/static/media/logo.2baf905b.png"
              height="50px"
            ></img>
            <p className="pt-3">
              Copyrights &copy; <strong>Arbopen</strong> All rights reserved{' '}
            </p>
          </div>
          <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 col-xl-4 footerclass1 pt-3  text-left">
            <p className="ml-3">Follow Us</p>
            {/* <div className="middle"> */}
            <div>
              <a className="btnFooter btn" href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btnFooter btn" href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btnFooter btn" href="#">
                <i className="fab fa-codepen"></i>
              </a>
              <a className="btnFooter btn" href="#">
                <i className="fab fa-instagram"></i>
              </a>
              {/* <a className="btnFooter btn" href="#">
                <i className="fab fa-youtube"></i>
              </a> */}
            </div>
          </div>
          <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 col-xl-4 footerclass">
            <div className={styles.contact_box}>
              <p>Contact Us</p>
              <button type="submit" className={styles.contact1}>
                <i className="fa-solid fa-phone-volume"></i> 9620112233
              </button>
              <button type="submit" className={styles.contact + ' mt-3'}>
                <i className="fa-solid fa-envelope"></i> contactus@arbopenexchange.com
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer1>
  </div>
);

export default Footer;
