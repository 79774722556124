import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, TextInput, ResponsiveContext } from 'grommet';

const NameGrade = ({ name, handleChange }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box direction="row" justify="evenly">
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'} alignSelf="start">
                <FormField label="Tracking Number">
                  <TextInput
                    color={'primary'}
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Type a name"
                    type="text"
                    disabled
                  />
                </FormField>
              </Box>
            </Box>
            <Box width="50%">
              <Box width={size !== 'large' ? '250px' : '300px'}>
                <FormField label="Cargo Number">
                  {/* <Select
                    plain
                    name="quality"
                    placeholder="Select a quality"
                    value={quality}
                    options={qualityOptions.map(qo => (
                      <span key={qo.val}>{qo.lab}</span>
                    ))}
                    onChange={({ option }) => handleSelect('quality', option)}
                  /> */}
                  <TextInput
                    color={'primary'}
                    name="quality"
                    onChange={handleChange}
                    placeholder="Enter cargo number"
                    type="text"
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

NameGrade.propTypes = {
  name: PropTypes.string.isRequired,
  quality: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default NameGrade;
