import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Select, ResponsiveContext } from 'grommet';
import { pricingOptions } from '../../../util/formFieldsUtils';

const Pricing = ({ pricing, handleSelect }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box margin={{ top: size !== 'large' ? '0px' : 'medium' }}>
          <Box align="center" direction="row" justify="evenly">
            <Box width="100%" alignSelf="start">
              <FormField label="Pricing">
                <Select
                  plain
                  name="pricing"
                  placeholder="Select a pricing"
                  value={pricing}
                  options={pricingOptions.map(qo => (
                    <span key={qo.val}>{qo.lab}</span>
                  ))}
                  onChange={({ option }) => handleSelect('pricing', option)}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

Pricing.propTypes = {
  pricing: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Pricing;
