/* Shipping Constraints RadioButtonGroup label component */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

const ScLabel = ({ title }) => {
  return (
    <Box direction="row" align="baseline">
      <Text weight="bold">{title}</Text>
    </Box>
  );
};

ScLabel.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ScLabel;
