import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Header, Image } from 'grommet';
import { Add, BarChart, Cycle, History } from 'grommet-icons';
import logo from '../../media/logo.png';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './NavBar.module.scss';
import { clearUser } from '../../actions/userActions';
import * as util from './../../util/util';
import { clearLoggedUser } from '../../actions/logggedUserActions';
import { rolesLogos } from '../../util/rolesLogos';
import axios from 'axios';
import adminlogo from '../../media/userlogo_121.png';

const activeNavLinkStyle = theme => ({
  color: theme.global.colors.danger,
  borderBottom: `1px solid ${theme.global.colors.danger}`,
  fontWeight: 'bold',
});

const NavBar = ({ theme, userDetails, loggedUser, location, clearUser, clearLoggedUser }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handler = () => {
      setOpen(false);
    };
    document.addEventListener('mouseup', handler);
  });

  function clickedLogout() {
    clearUser();
    clearLoggedUser();
    checkExistData();
  }

  function checkExistData() {
    console.log('logging out :>> ');
    const existLoggedUserData = localStorage.getItem('loggedUser');
    if (!existLoggedUserData) {
      return;
    }
    const userData = JSON.parse(existLoggedUserData);
    const logoutObj = { session: userData.session, user: userData.user };
    axios
      .post('/auth/logout', logoutObj)
      .then(res => {
        localStorage.removeItem('loggedUser');
        sessionStorage.removeItem('token');
        util.logout();
      })
      .catch(err => {
        console.log('err :>> ', err);
      });
  }

  return (
    <Header background={'header'} pad="medium" height="100px">
      <Box direction="row" align="center">
        <div>
          <Image src={logo} alt="Arbopen" height="100px" className={styles.logo_img} />
        </div>
        {userDetails &&
          userDetails.name &&
          loggedUser &&
          loggedUser?.otherDetails?.role !== 'Admin' && (
            <Box direction="row" margin={{ left: '80px' }} justify="around">
              <NavLink
                className={styles.navlink}
                activeStyle={activeNavLinkStyle(theme)}
                exact
                to="/"
              >
                <Cycle size="small" color={location.pathname === '/' && 'danger'} />
                Trading
              </NavLink>
              <NavLink
                className={styles.navlink}
                activeStyle={activeNavLinkStyle(theme)}
                exact
                to="/cap"
              >
                <Add size="small" color={location.pathname === '/cap' && 'danger'} />
                New CARGO
              </NavLink>
              <NavLink
                className={styles.navlink}
                activeStyle={activeNavLinkStyle(theme)}
                exact
                to="/trades"
              >
                <BarChart size="small" color={location.pathname === '/trades' && 'danger'} />
                My Trades
              </NavLink>
              <NavLink
                className={styles.navlink}
                activeStyle={activeNavLinkStyle(theme)}
                exact
                to="/history"
              >
                <History size="small" color={location.pathname === '/history' && 'danger'} />
                Trading history
              </NavLink>
            </Box>
          )}
      </Box>
      <Box direction="row" align="center">
        <NavLink exact to="/" className={styles.pr16}>
          {loggedUser && loggedUser?.otherDetails?.fullName && (
            <Button label={loggedUser?.otherDetails?.fullName} color="primary" />
          )}
        </NavLink>
        {userDetails && userDetails.name ? (
          <div className="App">
            <div className="menu-container">
              <div className={styles.menu_trigger}>
                <Button
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {' '}
                  {loggedUser && loggedUser?.otherDetails?.role === 'Admin' && (
                    <Image src={adminlogo} fit="contain" />
                  )}
                  {loggedUser && loggedUser?.otherDetails?.role !== 'Admin' && (
                    <Image src={rolesLogos[userDetails.name]} fit="contain" />
                  )}
                </Button>
              </div>
              <div
                className={styles.dropdown_menu + ' ' + (open ? styles.active : styles.inactive)}
              >
                <ul className={styles.ul}>
                  <div className={styles.link0}>
                    <NavLink className={styles.NavLink1} exact to="/">
                      <Button>Home</Button>
                    </NavLink>
                  </div>
                  <div className={styles.link1}>
                    <NavLink className={styles.NavLink1} exact to="/user">
                      <Button>My Profile</Button>
                    </NavLink>
                  </div>
                  {loggedUser && loggedUser?.otherDetails?.role === 'Admin' && (
                    <div className={styles.link2}>
                      <NavLink className={styles.NavLink2} exact to="/administration/userlist">
                        <Button Button>Administration</Button>
                      </NavLink>
                    </div>
                  )}
                  <div className={styles.link3}>
                    <span className={styles.NavLink3}>
                      <Button
                        Button
                        onClick={() => {
                          clickedLogout();
                        }}
                      >
                        Log out
                      </Button>
                    </span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </Box>
    </Header>
  );
};

NavBar.propTypes = {
  theme: PropTypes.object.isRequired,
  userDetails: PropTypes.shape({
    name: PropTypes.string,
  }),
  clearUser: PropTypes.func.isRequired,
  clearLoggedUser: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  loggedUser: PropTypes.shape({
    otherDetails: PropTypes.object,
  }),
};

const mapStateToProps = store => {
  return {
    userDetails: store.db.docs[store.user.id],
    loggedUser: store.loggedUser,
  };
};

const mapDispatchToProps = dispatch => ({
  clearUser: () => dispatch(clearUser()),
  clearLoggedUser: () => dispatch(clearLoggedUser()),
});
// function DropdownItem(props) {
//   return (
//     <li className="dropdownItem">
//       <img src={logicon} className={styles.img}></img>
//       <a>{props.text}</a>
//     </li>
//   );
// }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
